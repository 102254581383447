* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  /* overflow: hidden; */
  /* height: 100vh; */
}
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span {
  text-rendering: geometricPrecision;
}

textarea {
  resize: none;
}

.fp-404-error {
  padding-top: 100px;
  height: 100vh;
}

.fp-404-error .fp-error-404-bg {
  background-image: url("../Resource/wallpaper.png");
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  /* mix-blend-mode: hard-light; */
  width: 100%;
  height: 320px;
  top: 60%;
}

.fp-404-error .fp-error-404-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.fp-error-404-wrapper h1 {
  font-weight: 800 !important;
  font-size: 190px !important;
  float: left !important;
}

.fp-error-404-wrapper h2 {
  color: rgba(0, 0, 0, 0.3) !important;
  fill: rgba(0, 0, 0, 0.3) !important;
  font-size: 100px !important;
  float: right !important;
  margin-top: 25px;
}

.fp-404-error .fp-error-404-link {
  margin-top: 50px;
}

.fp-error-404-link p {
  color: #0073f0 !important;
  font-weight: 600 !important;
  font-size: 25px !important;
}

a {
  text-decoration: none !important;
  color: #00b1ab;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

/* DISABLE AUTHENTICATION BUTTONS */
.modal-content form button:disabled {
  background-color: #0143baaa;
}

/* color swatches */
.bg-theme-button-group {
  background: #d9e3f5;
}

.theme-bg {
  background-color: #00b1ab;
}

.theme-color {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  font-size: 20px;
}
.theme-color:hover {
  background-color: #000000;
  border: 1px solid #000000;
}

.w100 {
  width: 100%;
}

/* margin top sizes */
.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px;
}

/* margin bottom sizes */
.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb90 {
  margin-bottom: 90px;
}

.padd40 {
  padding: 5px 0;
}

.nav-link {
  color: #141414 !important;
  display: block;
  padding: 0;
}

a.nav-link.active {
  color: #db7030;
}

/* .landing-page-nav .nav-item .nav-link {
  color: #00b1ab !important;
  font-weight: 550;
} */

.custom-navbar-two {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.sticky-navbar {
  position: fixed;
  top: 10;
  width: 100%;
  z-index: 10;
}

a .navbar-brand {
  position: relative;
  z-index: 2;
  float: left;
  height: 32px;
}
.navbar-toggler {
  display: none;
}

.fp-mortgage-application-mobile-landscape-view {
  padding: 5px;
  border: 1px solid #00b1ab;
  margin-top: 5px;
}

.fp-mortgage-application-mobile-landscape-menu {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 80;
}

.fp-mortgage-application-mobile-landscape-menu
  .fp-mortgage-application-mobile-landscape-menu-wrapper {
  z-index: 90;
  width: 87vw;
  height: 29vh;
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  background-color: #fff;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper
  .fp-mortgage-application-mobile-landscape-menu-wrapper-navigation {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

/* .fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-close {
  left: 0;
} */

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation
  .fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-logo {
  width: 120px;
  height: 24px;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-list {
  border-top: 1px solid #ededed;
  padding: 10px;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-list ul {
  list-style: none;
  padding: 0 10px;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-list ul li {
  font-size: 16px;
  margin: 15px auto;
  font-weight: 450;
  color: #121212;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-list ul li a {
  color: #121212;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-list
  ul
  li
  a:hover {
  color: #00b1ab;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-action-btn {
  border-top: 1px solid #ededed;
  padding: 20px;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-action-btn
  .fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-action-login-btn {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 60px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0 5px 16px 0 rgba(0, 177, 171, 0.45);
  text-rendering: geometricPrecision;
  width: 100%;
}

.fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-action-btn
  .fp-mortgage-application-mobile-landscape-menu-wrapper-navigation-action-login-btn:hover {
  background-color: #000000;
  border: 1px solid #000000;
  box-shadow: none;
}

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: #0143ba;
  background-color: #0143ba;
}

.navbar-nav li.nav-item {
  display: inline-block;
  line-height: 40px;
  margin-left: 20px;
  justify-content: space-between;
}

a.nav-link {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;
  color: #404040;
}

a.btn-login {
  background-color: transparent;
  border: 1px solid #00b1ab;
  padding: 10px 20px !important;
  margin: 5px auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #00b1ab !important;
  font-weight: 600;
}

a.btn-login:hover {
  background-color: #000000;
  border: 1px solid #000000;
  padding: 10px 20px !important;
  margin: 5px auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
}

a.btn-register {
  background-color: #0143ba;
  border: 1px solid #0143ba;
  padding: 10px 20px !important;
  margin: 5px auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  box-shadow: 0px 5px 16px 0px rgba(148, 163, 250, 0.75);
}

a.btn-register:hover {
  background-color: #1564f3;
  border: 1px solid #1564f3;
  padding: 10px 20px !important;
  margin: 5px auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
}

.custom-navbar-two a.btn-register {
  color: #fff !important;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
  }
}

.navbar-nav li {
  animation: FadeIn 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  animation-fill-mode: both;
}

.navbar-nav li:nth-child(1) {
  animation-delay: 0.3s;
}

.navbar-nav li:nth-child(2) {
  animation-delay: 0.6s;
}

.navbar-nav li:nth-child(3) {
  animation-delay: 0.9s;
}

.navbar-nav li:nth-child(4) {
  animation-delay: 1.2s;
}

.navbar-nav li:nth-child(5) {
  animation-delay: 1.5s;
}

.navbar-nav li:nth-child(6) {
  animation-delay: 1.8s;
}

.navbar-nav li:nth-child(7) {
  animation-delay: 2.1s;
}

.navbar-nav li:nth-child(8) {
  animation-delay: 2.4s;
}

/* Animation */
@keyframes changeOpacity {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.fade:not(.show) {
  opacity: 0;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.fade {
  transition: opacity 0.15s linear;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

.wd-sm-400 {
  width: 400px;
}

.wd-sm-600 {
  max-width: 600px;
}

.modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}

.modal-content {
  border-color: rgba(28, 39, 60, 0.16);
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.fp-modal-body-wrapper {
  padding: 40px;
}

.modal-content .close {
  font-weight: 300;
  font-size: 28px;
  line-height: 0.87;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #1b2e4b;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-content .fp-login-modal-close {
  right: 15px;
  top: 15px;
  position: absolute;
  z-index: 1400;
}

.modal-dialog {
  pointer-events: none;
}

.fp-login-page-logo {
  background-image: url(../Resource/finance-plus-long.png);
  background-repeat: no-repeat;
  background-size: 130px;
  height: 60px;
  margin: 0 auto;
  /* max-width: 573px; */
  background-position: 50%;
  margin-bottom: 20px;
}

.fp-login-title-header {
  padding: 32px 0 27px;
  font-size: 23px;
  line-height: 33px;
  margin: 0 auto;
  max-width: 25ch;
  color: #0143ba;
  text-align: center;
  font-weight: 520;
  text-transform: uppercase;
}

.fp-login-form-wrapper input {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 45px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}

.fp-login-form-wrapper label {
  color: #706e6e;
  font-size: 16px;
}

.fp-login-form-wrapper .form-control::placeholder {
  color: #c0ccda;
}

form .form-control:valid ~ .invalid,
form .form-control ~ span.hide.invalid,
form .input-group + .hide.invalid,
form .date-error {
  display: none;
}

form .form-control:invalid ~ .invalid,
form .input-group + .invalid,
form.was-validated .date-error.invalid-date {
  font-size: 0.8em;
  font-style: italic;
  color: #f00;
  animation: changeOpacity 3.5s infinite;
  -webkit-animation: changeOpacity 3.5s infinite;
}

form.was-validated .date-error.invalid-date {
  display: block;
}

/* form.was-validated .form-control:valid {
  background-image: none;
} */

.password-visibility {
  height: 16px;
  cursor: pointer;
  fill: #0a2e65;
  top: 20px;
  width: 16px;
  opacity: 0.3;
  position: absolute;
  right: 16px;
}

.password-visibility.toggled {
  opacity: 1;
}

.fp-checkbox.fp-keep-me-logged-in {
  line-height: 20px;
  margin: 15px 0 21px;
  width: 50%;
  display: inline-block;
  position: relative;
}

.fp-checkbox.fp-send-notification {
  line-height: 20px;
  margin: 15px 0 21px;
  width: 100%;
  display: inline-block;
  position: relative;
}

.fp-checkbox.fp-keep-me-logged-in input,
.fp-checkbox.fp-send-notification input {
  border: 0;
  cursor: pointer;
  height: 1px;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 1px;
}

.fp-keep-me-logged-in input,
.fp-send-notification input {
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  color: #8d8d8d;
  line-height: 17px;
  padding: 0 16px;
  appearance: none;
}

.fp-checkbox.fp-keep-me-logged-in label::before,
.fp-checkbox.fp-send-notification label::before {
  border: 1px solid #bcbcbc;
  border-radius: 3px;
  content: "";
  display: block;
  height: 20px;
  left: 0;
  margin-top: -12.5px;
  position: absolute;
  top: 50%;
  width: 20px;
}

.fp-checkbox.fp-keep-me-logged-in label,
.fp-checkbox.fp-send-notification label {
  display: inline-block;
  font-size: 13.5px;
  line-height: 14px;
  margin-left: 30px;
  vertical-align: middle;
}

.fp-checkbox.fp-send-notification label {
  font-size: 12px;
}

.fp-checkbox.fp-keep-me-logged-in input:checked + label::before,
.fp-checkbox.fp-send-notification input:checked + label::before {
  background-image: url(../Resource/checkmark.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 11px 8px;
}

.fp-login-form-wrapper .fp-login-options {
  float: right;
  line-height: 20px;
  margin: 12px 0 20px;
  text-align: right;
  width: 50%;
}

.fp-login-form-wrapper .fp-login-options .fp-forgotPassword {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 100%;
}

.fp-login-form-wrapper .fp-login-options .fp-forgotPassword a {
  color: #8d8d8d;
  font-size: 13.5px;
  text-decoration: none;
}

.fp-terms-privacy-policy {
  clear: both;
  font-size: 14px;
  line-height: 22px;
  padding: 3px 0 24px;
  text-align: center;
  color: #8d8d8d;
}

.fp-create-account-wrapper {
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
  color: #8d8d8d;
}

.fp-create-account-wrapper a {
  text-decoration: none !important;
}

.fp-terms-privacy-policy a,
.fp-create-account-wrapper a {
  border-bottom: 1px solid #706e6e;
  color: #706e6e;
}

.error-list.fp-errors-display-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.error-list.fp-errors-display-list .error-item {
  font-size: 14px;
  margin: 10px 0;
  color: #f00;
  animation: changeOpacity 3.5s infinite;
  display: block;
}
