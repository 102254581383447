.fp-mortgage-sidebar {
  width: 250px;
  border-bottom: 1px solid #f2f6f9;
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 10px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: 0 8px 20px #e5e4e6;
}

.fp-mortgage-nav-wrapper {
  padding-top: 100px;
}

.fp-mortgage-nav-wrapper .fp-nav-link {
  margin: 0 -10px;
  padding: 5px 10px;
  cursor: pointer;
  display: block;
  min-height: 50px;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
}

.fp-mortgage-nav-wrapper .fp-nav-link:hover {
  background: rgb(0, 177, 171, 0.17);
  border-left: 3px solid #00b1ab;
}

.fp-mortgage-nav-wrapper .fp-nav-link.fp-active-nav {
  background: rgb(0, 177, 171, 0.17);
  border-left: 3px solid #00b1ab;
  color: #00b1ab;
  font-weight: 500;
}

.fp-mortgage-nav-wrapper .fp-nav-link img {
  height: calc(15px + 3px);
  margin: 0 15px 0 10px;
}

.fp-mortgage-nav-wrapper .fp-nav-group {
  margin: 0 -10px;
  padding: 0 10px 15px;
  border-bottom: 1px solid #f2f6f9;
}

.fp-nav-link.border-line-top {
  border-top: 1px solid #f2f6f9;
  padding-top: 20px;
}

.fp-mortgage-nav-wrapper .fp-nav-group .fp-nav-group-subtile {
  color: #bbbbbb;
  font-size: 10px;
  padding: 0 10px;
  margin: 20px 5px;
  letter-spacing: 0.7;
  text-transform: uppercase;
  font-weight: 500;
}

/* Mobile Menu */

.fp-mobile-navigation-user-avatar {
  display: none;
}

/* .fp-mobile-navigation-user-avatar-navigation-logo {
  width: 120px;
  height: 22px;
  margin: 15px 10px;
}

.fp-mortgage-sidebar .toggleCancel {
  margin-left: 60px;
}

.fp-mortgage-sidebar .fp-mobile-navigation-user-avatar {
  padding: 10px 0 35px 10px;
  margin-bottom: 20px;
} */
