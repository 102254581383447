.application-progress-bar-wrapper {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background: #eee;
  padding: 0px;
  margin-bottom: 60px;
}

.application-progress-bar-wrapper .application-progress-bar {
  background: #00b1ab;
  height: 100%;
}
