#fp-landing-footer.fp-landing-footer {
  background: #022f8f;
}

.fp-landing-footer .fp-landing-footer-bg {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  mix-blend-mode: hard-light;
  width: 100%;
  height: 400px;
}

.fp-landing-footer .fp-landing-footer-top {
  padding: 50px 0;
}

.fp-landing-footer-top .fp-landing-footer-company-info {
  margin-top: 30px;
}

.fp-landing-footer-company-info .fp-landing-footer-finance-logo {
  background-image: url("../Resource/finance-plus-logo-light-bottom.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  height: 40px;
  width: 200px;
  margin-left: -30px;
}

.fp-landing-footer-company-info .fp-landing-footer-nda-info {
  display: flex;
  justify-content: flex-start;
}

.fp-landing-footer-nda-info .fp-landing-footer-nda-info-img {
  background: url("../Resource/fp-landing-footer-nda.png");
  background-repeat: no-repeat;
  /* background-position: 30%; */
  background-size: contain;
  height: 80px;
  width: 80px;
  float: left;
}

.fp-landing-footer-social-media-links {
  padding: 10px 0;
}

.fp-landing-footer-social-media-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fp-landing-footer-social-media-links ul li {
  display: inline-block;
  position: relative;
  padding-right: 20px;
}

.fp-landing-footer-site-links h4 {
  font-size: 22px;
  line-height: 39px;
  color: #ffffff;
  font-weight: 600;
}

.fp-landing-footer-site-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.fp-landing-footer-site-links ul li a {
  color: #ffffff;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
}

.fp-landing-footer-site-links ul li a:hover {
  color: #00b1ab;
  font-weight: 500;
  margin-left: 10px;
  transition: 0.25s ease-out;
  animation-name: slide;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  /* animation-fill-mode: forwards; */
}

.fp-landing-footer-nda-info p {
  margin-left: 10px;
  color: #ffffff;
  font-size: 13px;
  line-height: 23px;
  /* font-style: normal; */
  font-weight: 400;
}

.fp-landing-footer .fp-landing-footer-bottom {
  background: #00827d;
  mix-blend-mode: normal;
  padding: 5px 0;
  margin-bottom: 35px;
}

.fp-landing-footer .fp-landing-footer-bottom p {
  color: #ffffff;
  text-align: center;
  padding: 0px 120px;
  margin-top: 5px;
  font-size: 14px;
  /* line-height: 20px; */
  font-weight: 450;
}

.fp-lender-rates-wrapper {
  background: #050d17;
  font-size: 12px;
  color: #fff;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  bottom: 0;
  padding: 10px 0px;
}

.fp-lender-rates-wrapper.hide {
  display: none;
}

.fp-lenders-rates-list-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 3850px;
}

.fp-lenders-rates-list-wrapper .fp-lenders-rates-list {
  display: inline-block;
  animation: marquee 60s linear infinite;
}
.fp-lenders-rates-list span {
  margin: 0 5px;
}
