.fp-mortgage-applications-all {
  background: #dee3e6;
  border-radius: 5px;
}

.fp-mortgage-applications-all
  .fp-mortgage-applications-multi-tab-actions-buttons {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
}

.fp-mortgage-applications-multi-tab-actions-buttons
  .fp-mortgage-applications-multi-tab
  span {
  margin-right: 30px;
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  font-size: 13px;
}

.fp-mortgage-applications-multi-tab-actions-buttons
  .fp-mortgage-applications-multi-tab
  span.fp-active-menu-tab {
  background: #ffffff;
  padding: 10px 25px;
  color: #555555;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: 0 5px 10px #c1c2c24d;
}

.fp-mortgage-applications-actions-buttons .fp-dashboard-chat-message-actions {
  margin-right: 10px;
}

.fp-mortgage-applications-all .fp-mortgage-applications-list-wrapper {
  background: #ffffff;
  padding: 20px;
}

.responsive-table li {
  border-radius: 3px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.responsive-table .table-header {
  background-color: #ffffff;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  color: #555555;
}
.responsive-table .table-row {
  background-color: #f0f4f7;
  color: #555555;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.responsive-table .table-row.approved {
  border-left: 3px solid #54af19;
}

.responsive-table .table-row.rejected {
  border-left: 3px solid #ff0000;
}

.responsive-table .table-row.pending {
  border-left: 3px solid #0073f0;
}

.responsive-table .table-row.submitted {
  border-left: 3px solid #0c5460;
}

.responsive-table .table-row.in-review,
.responsive-table .table-row.verifying {
  border-left: 3px solid #856404;
}

.responsive-table .col-1 {
  flex-basis: 10%;
}
.responsive-table .col-2 {
  flex-basis: 40%;
}
.responsive-table .col-3 {
  flex-basis: 25%;
}
.responsive-table .col-4 {
  flex-basis: 25%;
}
.responsive-table .col-5 {
  flex-basis: 13%;
}
@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }
  .responsive-table li {
    display: block;
  }
  .responsive-table .col {
    flex-basis: 100%;
  }
  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }
  .responsive-table .col:before {
    color: #6c7a89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 20%;
    text-align: right;
  }
}

.responsive-table .table-row span.approved {
  padding: 8px 30px;
  background: #e6f3dd;
  color: #54af19;
  font-weight: 700;
  font-size: 12px;
}

.responsive-table .table-row span.rejected {
  padding: 8px 30px;
  background: #e8d0d2;
  color: #ff0000;
  font-weight: 700;
  font-size: 12px;
}

.responsive-table .table-row span.pending {
  padding: 8px 30px;
  background: #cce1f6;
  color: #0073f0;
  font-weight: 700;
  font-size: 12px;
}

.responsive-table .table-row span.submitted {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 8px 30px;
  font-weight: 700;
  font-size: 12px;
}

.responsive-table .table-row span.in-review,
.responsive-table .table-row span.verifying {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 8px 30px;
  font-weight: 700;
  font-size: 12px;
}

/* .fp-mortgage-reference-no-status .fp-mortgage-status span.pending {
  background: #cce1f6;
  color: #0177f3;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.approved {
  background: #d9ead6;
  color: #54af19;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.rejected {
  background: #e8d0d2;
  color: #ba0000;
} */
