/* Test two */

#fp-mortgage-application-type {
  background: #ffffff;
  overflow: hidden;
}

.fp-mortgage-application-type-wrapper {
  padding: 73px 0 40px;
}

.fp-mortgage-application-type-bg {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  mix-blend-mode: hard-light;
  width: 100%;
  height: 100vh;
}

.fp-mortgage-application-type-wrapper-left {
  /* background: #00b1ab; */
  height: 100vh;
  border-right: 1px solid rgb(18, 18, 18, 0.058);
}

.fp-mortgage-application-type-wrapper-right {
  /* background: #121212; */
  height: 100vh;
}

.fp-mortgage-application-type-wrapper-left a,
.fp-mortgage-application-type-wrapper-right a {
  text-decoration: none;
  color: #121212;
}

.fp-mortgage-application-type-wrapper-left:hover a,
.fp-mortgage-application-type-wrapper-right:hover a {
  text-decoration: none;
  color: #fff;
}

.fp-mortgage-application-type-wrapper-left:hover,
.fp-mortgage-application-type-wrapper-right:hover {
  background-image: linear-gradient(246deg, #00b1ab, rgb(0, 177, 171, 1));
  transition: 0.6s;
}

.fp-mortgage-application-type-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 90px;
  margin: 20px 0;
}

.fp-mortgage-application-type-icon.regular {
  background-image: url("../Resource/home-loanpng.png");
}

.fp-mortgage-application-type-wrapper-left:hover
  .fp-mortgage-application-type-icon.regular {
  background-image: url("../Resource/home-loan-alt.png");
}

.fp-mortgage-application-type-icon.nhf {
  background-image: url("../Resource/nhf-loan.png");
}

.fp-mortgage-application-type-wrapper-right:hover
  .fp-mortgage-application-type-icon.nhf {
  background-image: url("../Resource/nhf-loan-alt.png");
}

.fp-mortgage-application-type-content-list {
  padding: 80px 0;
}

.fp-mortgage-application-type-content-list h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  line-height: 7px;
}

.fp-mortgage-application-type-content-list h4 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  margin-top: 10px;
}

.fp-mortgage-application-type-content-list p {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
  font-style: italic;
  text-transform: unset;
}

.fp-mortgage-application-type-mortgage-lenders {
  margin: 30px 0;
  padding: 0 40px;
}

.fp-mortgage-application-type-content-list hr {
  width: 350px;
}

.fp-mortgage-application-type-mortgage-lenders-logo img {
  height: 40px;
}

.fp-mortgage-application-type-mortgage-lenders-logo.first-trust img {
  width: auto;

  /* height: 50px; */
}

.fp-mortgage-application-type-mortgage-lenders-logo.standard-chartered img {
  width: auto;
  /* height: 50px; */
}

.fp-mortgage-application-type-mortgage-lenders-logo.standard-ibtc img {
  width: auto;
  /* height: 50px; */
}

.fp-mortgage-application-type-mortgage-lenders-logo.federal-mortgage img {
  width: auto;
  height: 100px !important;
  margin: -30px 0;
}

.fp-mortgage-application-type-mortgage-nmrc-powered-logo {
  position: absolute;
  bottom: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  height: 100px;
  top: 250px;
  left: 0;
  right: 0;
  margin-top: 200px;
}

/* .fp-mortgage-application-type-mortgage-lenders-logo.federal-mortgage .fmbn {
  height: 100px !important;
} */
