.fp-forgot-password-img {
  background: url(../Resource/fp-forgot-password.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30%;
  height: 300px;
  width: 100%;
  margin-top: 30px;
}

.fp-forgot-password-page-subtitle {
  font-size: 27px;
  font-weight: 700;
  color: #0a2e654d !important;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fp-forgot-password-page-text {
  font-weight: 400;
  /* color: #8d8d8d; */
  color: #1c252c;
  text-align: center;
  margin: 20px 0;
  line-height: 1.4;
}

/* Reset Password */
.code_group {
  margin-bottom: 20px;
  text-align: left;
}

.code_group .form-control {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  border-radius: 0;
  display: inline-block;
  width: 40px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  margin-right: 7px;
  text-align: center;
  line-height: 1;
}

form.been-validated .code_group .form-control:valid {
  border-color: #28a745;
}

form.been-validated .code_group .form-control:invalid {
  border-color: #dc3545;
}

form.been-validated .code_group + div .form-control:invalid + .invalid-feedback {
  display: block;
}
