.pdf-viewer-wrapper#myModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow: auto;
  padding-top: 100px;
}

.pdf-viewer-wrapper {
  background: #222222cc;
}

.pdf-viewer-wrapper .close {
  top: 100px;
}

.pdf-viewer .react-pdf__Page {
  display: flex;
  justify-content: center;
  padding: 60px 0px;
}

.pdf-viewer .react-pdf__Page span,
.pdf-viewer-wrapper .react-pdf__Page div {
  display: none;
}

.pdf-viewer-wrapper .page-description {
  position: fixed;
  top: 100px;
  left: 20px;
  color: #fff;
  font-size: 24px;
}

.pdf-viewer-wrapper .navigation-wrapper {
  position: fixed;
  top: 50%;
  width: 100%;
}

.pdf-viewer-wrapper .navigation-wrapper span {
  font-size: 32px;
  color: #fff;
  padding: 0px 20px;
  border-radius: 0 6px 6px 0;
  position: absolute;
}

.pdf-viewer-wrapper .navigation-wrapper span.hide {
  visibility: hidden;
}

.pdf-viewer-wrapper .navigation-wrapper .prev {
  left: 0;
}

.pdf-viewer-wrapper .navigation-wrapper .next {
  right: 150px;
  border-radius: 6px 0 0 6px;
}

.pdf-viewer-wrapper .navigation-wrapper span:hover,
.pdf-viewer-wrapper .navigation-wrapper span:focus {
  background: #000;
}

.pdf-viewer-wrapper .download-btn-wrapper {
  display: flex;
  justify-content: center;
}

.pdf-viewer-wrapper .download-btn {
  display: block;
  padding: 10px 20px;
  background: blue;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 30px;
}