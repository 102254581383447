.fp-multi-step-form-wrapper {
  padding: 90px 60px 10px;
  /* margin: 120px 0 0px; */
  background: #fff;
  box-shadow: -10px -40px 120px -40px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.fp-multi-step-form-wrapper.half {
  width: 59%;
}

.fp-multi-step-form-wrapper.full {
  width: 100%;
}

.fp-multi-step-form-wrapper .fp-multi-step-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: -55px;
}

.fp-multi-step-form-wrapper .fp-multi-step-form-text {
  margin-top: 10px;
  font-size: 18px;
}

.fp-multi-step-form-text .fp-multi-step-number {
  color: #0143ba;
  font-weight: 800;
}
