.fp-mortgage-content-wrapper {
  padding-top: 55px;
  width: calc(100% - 250px);
  min-height: 100vh;
  margin-left: auto;
  background-color: #eff3f6;
  padding-bottom: 15px;
  overflow-x: hidden;
}

.fp-dashboard-section {
  margin-top: 30px;
}

.fp-admin-analytic-box {
  background: #fff;
  height: 100%;
  padding: 175px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.fp-mortgage-application-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fp-mortgage-applications-list-wrapper {
  background: #ffffff;
  padding: 10px 40px 20px 0px;
}

.fp-mortgage-application-title-wrapper h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.7;
  color: #555555;
  margin: 0 15px 0 0;
  /* border-right: 2px solid #ccc; */
  padding-right: 20px;
}

.fp-mortgage-application-menu-tabs span {
  font-size: 13.5px;
  margin: 0 14px;
  color: #bfbfbf;
  font-weight: 500;
}

.fp-mortgage-application-menu-tabs span:hover {
  cursor: pointer;
}

.fp-mortgage-application-menu-tabs .fp-active-menu-tabs {
  background: #ffffff;
  padding: 7px 20px;
  color: #555555;
  border-radius: 3px;
}

.fp-mortgage-application-contents {
  margin: 30px 0;
}

.fp-user-mortgage-applications-wrapper {
  background: #ffffff;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.fp-user-mortgage-applications-list.responsive-table {
  padding: 0px 15px !important;
}

.fp-user-mortgage-applications-list.responsive-table li {
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  color: #333333;
}
.fp-user-mortgage-applications-list.responsive-table .table-header {
  background-color: #ffffff;
  font-size: 13px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  padding: 10px;
  color: #333333;
}
.fp-user-mortgage-applications-list.responsive-table .table-row {
  background-color: #f0f4f7;
  padding: 12px;
}
.fp-user-mortgage-applications-list.responsive-table .col-1 {
  flex-basis: 10%;
}
.fp-user-mortgage-applications-list.responsive-table .col-2 {
  flex-basis: 15%;
}
.fp-user-mortgage-applications-list.responsive-table .col-3 {
  flex-basis: 25%;
}
.fp-user-mortgage-applications-list.responsive-table .col-4 {
  flex-basis: 25%;
}

.responsive-table .table-row span.approved {
  padding: 8px 30px;
  background: #e6f3dd;
  color: #54af19;
  font-weight: 500;
  font-size: 12px;
}

.responsive-table .table-header {
  font-weight: 500 !important;
}
.responsive-table .table-row {
  font-weight: 500 !important;
  padding: 12px;
}

.responsive-table .table-row span.rejected {
  padding: 8px 30px;
  background: #e8d0d2;
  color: #ff0000;
  font-weight: 500;
  font-size: 12px;
}

.responsive-table .table-row span.pending {
  padding: 8px 30px;
  background: #cce1f6;
  color: #0073f0;
  font-weight: 500;
  font-size: 12px;
}

@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }
  .responsive-table li {
    display: block;
  }
  .responsive-table .col {
    flex-basis: 100%;
  }
  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }
  .responsive-table .col:before {
    color: #6c7a89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}

.fp-lender-bg-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 20px;
  width: 120px;
  left: 0;
  margin-bottom: 10px;
}
