* {
  margin: 0px;
  padding: 0px;
}

.calender-view-grand-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.calendar-view-wrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  background: #eee;
  padding-bottom: 20px;
  max-height: 520px;
}

.calendar-view-wrapper .calendar-view-header,
.calendar-view-wrapper .header-navigator,
.calendar-view-wrapper .year-list,
.calendar-view-wrapper .calendar,
.calendar-view-wrapper .controls {
  padding: 0px 20px;
}

.calendar-view-wrapper .calendar-view-header {
  background-color: #00b1ab;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.calendar-view-wrapper .header-navigator,
.calendar-view-wrapper .year-list,
.calendar-view-wrapper .calendar,
.calendar-view-wrapper .controls {
  padding-top: 20px;
}

.calendar-view-wrapper .calendar-view-header {
  display: flex;
  flex-direction: column;
}

select {
  width: 100%;
  background: none;
  border: none;
}

select option {
  display: flex;
  justify-content: center;
}

.calendar-view-wrapper .calendar-view-header .year-view {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.calendar-view-wrapper .calendar-view-header .down-facing-arrow {
  color: #eee;
  font-weight: 600;
  margin-left: 5px;
}

.calendar-view-wrapper .calendar-view-header .year-view > div:first-child {
  margin-bottom: 10px;
  font-size: 1em;
  letter-spacing: 1.2px;
  font-weight: 600;
  color: #eeeeeeee;
  display: flex;
}

.calendar-view-wrapper .calendar-view-header .year-view > div:not(first-child) {
  font-size: 1.4em;
  font-weight: 600;
}

.calendar-view-wrapper .header-navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}

.calendar-view-wrapper .header-navigator span {
  margin-top: -5px;
}

.calendar-view-wrapper .header-navigator .month-year {
  font-size: 18px;
}

.calendar-view-wrapper .year-list {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.calendar-view-wrapper .year-list .year-in-list {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.calendar-view-wrapper .year-list .current-year {
  transform: scale(1.4);
  color: #00b1ab;
}

.calendar-view-wrapper .calendar {
  display: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 30px);
  grid-row-gap: 10px;
  padding-bottom: 10px;
}

.calendar-view-wrapper .calendar .day:hover,
.calendar-view-wrapper .year-view > *:hover,
.calendar-view-wrapper .controls button:hover,
.calendar-view-wrapper .day-wrapper .day:hover,
.calendar-view-wrapper .header-navigator span:hover,
.calendar-view-wrapper .header-navigator span:hover,
.calendar-view-wrapper .year-list .year-in-list:hover {
  cursor: pointer;
}

.calendar-view-wrapper .calendar .day-wrapper {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-view-wrapper .calendar span {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-view-wrapper .calendar .current {
  color: white;
  border-radius: 50%;
  background-color: #00b1ab;
}

.calendar-view-wrapper .controls {
  display: flex;
  justify-content: space-between;
}

.calendar-view-wrapper .controls div {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.calendar-view-wrapper .controls button {
  color: #00b1ab;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  background: none;
  border: none;
}
