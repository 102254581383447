.fp-mortgage-applications-multi-tab .tabs {
  height: 30px;
}

.fp-mortgage-applications-multi-tab .tabs:hover {
  cursor: pointer;
}

.fp-mortgage-content-wrapper {
  padding-top: 55px;
  width: calc(100% - 250px);
  min-height: 100vh;
  margin-left: auto;
  background-color: #eff3f6;
  padding-bottom: 15px;
  overflow-x: hidden;
}

.fp-dashboard-section {
  margin-top: 30px;
}

.fp-dashboard-section-error-wrapper {
  /* background: rgba(0, 122, 245, 0.169); */
  background: rgb(0, 177, 171, 0.169);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;
  color: #00b1ab;
  font-size: 14px;
  font-weight: 450;
  animation: changeOpacity 3.5s infinite;
}
.fp-dashboard-section-error-wrapper svg {
  margin-left: 1.2rem !important;
  margin-right: 1.2rem !important;
  color: #00b1ab;
}

.fp-dashboard-section-error-wrapper a {
  text-decoration: underline 1px rgba(0, 122, 245, 0.469) !important;
  color: #00b1ab;
}

.fp-welcome-box {
  background: #fff;
  height: 100%;
  padding: 35px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.fp-dashboard-profile-edit-wrap {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  padding: 0;
  max-width: 300px;
}

.fp-mortgage-date-caption-apply-for-mortgage-wrapper::before {
  content: "";
  background-color: rgb(233, 232, 232, 0.69);
  position: absolute;
  width: 1px;
  height: 120px;
  top: 18.5%;
  left: 86%;
}

.fp-mortgage-date-caption-apply-for-mortgage-wrapper {
  padding: 0 40px;
  /* padding: 0 0 0px 25px; */
}

.fp-mortgage-date-caption-apply-for-mortgage-wrapper span {
  font-size: 15px;
  color: #bbbbbb;
  /* margin: 10px 0 0; */
}

.fp-mortgage-date-caption-apply-for-mortgage-wrapper h2 {
  font-size: 29px;
  color: #555555;
  /* margin: 10px 0; */
  line-height: 1.7;
}

.fp-mortgage-date-caption-apply-for-mortgage-wrapper p {
  font-size: 15px;
  color: #555555;
  margin: 10px 0 30px;
  line-height: 1.7;
  /* max-width: 550px; */
  font-weight: 300;
}

.fp-dashboard-mortgage-loan-requirement-status-wrap {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 30px 0 0;
  /* padding: 30px 40px 0 0; */
  /* margin: 0 20px; */
}
.fp-dashboard-mortgage-loan-requirement-status-wrap
  .fp-dashboard-mortgage-loan-requirement-status {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  flex-basis: 30%;
}
.fp-dashboard-mortgage-loan-requirement-status-wrap
  .fp-dashboard-mortgage-loan-requirement-status
  svg {
  margin: 15px 0;
}

.fp-dashboard-mortgage-loan-requirement-status-wrap h4 {
  width: 100px;
  font-size: 13px;
  color: #555555;
  font-weight: 400;
  margin: auto 10px;
}

.fp-credit-score {
  z-index: 1000;
  position: absolute;
  left: 75%;
}

.fp-mortgage-credit-score-content {
  background: #022f8f;
  color: #fff;
  border-bottom-left-radius: 30px;
  padding: 30px 30px 115px;
}

.fp-mortgage-credit-score-content .fp-mortgage-wallet {
  background-image: url("../Resource/walletIconWhite.png");
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: cover;
  height: 50px;
  /* width: 100px; */
  left: 0;
  right: 40px;
  margin-bottom: 10px;
}

.fp-mortgage-credit-score-content h2 {
  font-size: 27px;
  line-height: 1.3;
}

.fp-mortgage-credit-score-content p {
  font-size: 14px;
  line-height: 1.2;
  margin: 25px auto;
}

.fp-mortgage-credit-score-content .fp-mortgage-bottom-pattern {
  background-image: url("../Resource/lenderIcon.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30%;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 140px;
  bottom: 0;
  top: 52%;
  background-blend-mode: color;
  clip: rect(0px, 128px, 185px, 0px);
  z-index: 100;
}

.fp-mortgage-application-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 20px; */
}

.fp-mortgage-application-title-wrapper h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.7;
  color: #555555;
  margin: 0 15px 0 0;
  /* border-right: 2px solid #ccc; */
  padding-right: 20px;
}

.fp-mortgage-application-menu-tabs span {
  font-size: 12px;
  margin: 0 14px;
  color: #bfbfbf;
  font-weight: 500;
}

.fp-mortgage-application-menu-tabs span:hover {
  cursor: pointer;
}

.fp-mortgage-application-menu-tabs .fp-active-menu-tab {
  background: #ffffff;
  padding: 7px;
  color: #555555;
  border-radius: 3px;
}

.fp-mortgage-application-content {
  /* overflow-x: scroll; */
  margin: 20px 0;
  /* overflow-y: hidden; */
  /* flex-wrap: nowrap; */
  display: flex;
  position: relative;
}

/* .fp-mortgage-application-list {
} */

.fp-mortgage-application-content .fp-application-details {
  background: #fff;
  padding: 10px 20px 0;
  border-radius: 10px;
  width: 400px;
  margin: 0 10px 0 0;
}

.fp-mortgage-application-content .fp-application-blank-details {
  background: transparent;
  padding: 10px 20px 0;
  border-radius: 10px;
  width: 400px;
  margin: 0 10px 0 0;
  border: 1px solid #00b1ab;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  height: 308.2px;
}

.fp-mortgage-application-content
  .fp-application-blank-details
  .fp-application-blank-detail-cta-btn-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 130px;
  display: flex;
  justify-content: center;
}

.fp-application-blank-detail-cta-btn {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 15px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
  width: 55%;
  box-shadow: 0px 10px 16px 0px rgb(0, 177, 171, 0.38);
}

.fp-application-blank-detail-cta-btn:hover {
  background-color: #000000;
  border: 1px solid #000000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.fp-mortgage-reference-no-status {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eff3f6;
  /* padding-bottom: 10px; */
}

.fp-mortgage-reference-no-status .fp-mortgage-reference-no span {
  font-size: 14px;
  color: #bbbbbb;
}
.fp-mortgage-reference-no-status .fp-mortgage-reference-no h4 {
  font-size: 14px;
  color: #555555;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span {
  align-items: center;
  margin-top: 10px;
  padding: 10px 20px;
  font-weight: 400;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.submitted {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.in-review,
.fp-mortgage-reference-no-status .fp-mortgage-status span.verifying {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.pending {
  background: #cce1f6;
  color: #0177f3;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.approved {
  background: #d9ead6;
  color: #54af19;
}

.fp-mortgage-reference-no-status .fp-mortgage-status span.rejected {
  background: #e8d0d2;
  color: #ba0000;
}

.fp-mortgage-loan-amount {
  padding: 10px 0;
}

.fp-mortgage-loan-amount span {
  font-size: 14px;
  color: #bbbbbb;
}

.fp-mortgage-loan-amount h4 {
  font-size: 16px;
  color: #555555;
  font-weight: 550;
  margin-top: 2px;
}

.fp-mortgage-loan-amount h4::before {
  content: "₦";
  padding-right: 5px;
}

.fp-mortgage-application-date-loan-type {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.fp-mortgage-application-date-loan-type .fp-mortgage-application-date span,
.fp-mortgage-application-date-loan-type
  .fp-mortgage-application-loan-type
  span {
  font-size: 14px;
  color: #bbbbbb;
}

.fp-mortgage-application-date-loan-type .fp-mortgage-application-date h4,
.fp-mortgage-application-date-loan-type .fp-mortgage-application-loan-type h4 {
  font-size: 16px;
  color: #555555;
  font-weight: 550;
  margin-top: 2px;
}

.fp-mortgage-application-lender-interest {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eff3f6;
  padding-bottom: 10px;
  /* margin-top: 20px; */
}

.fp-mortgage-application-lender-interest .fp-mortgage-application-lender span,
.fp-mortgage-application-lender-interest
  .fp-mortgage-application-interest
  span {
  font-size: 14px;
  color: #bbbbbb;
}

.fp-mortgage-application-lender .fp-mortgage-application-lender-icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 20px;
  width: 120px;
  left: 0;
  margin-bottom: 10px;
}

.fp-mortgage-application-lender-interest .fp-mortgage-application-lender h4,
.fp-mortgage-application-lender-interest .fp-mortgage-application-interest h4 {
  font-size: 15px;
  color: #555555;
  font-weight: 550;
  margin-top: 2px;
  max-width: 150px;
  line-height: 1.5;
}

.fp-mortgage-application-document-view {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 10px; */
}

.fp-mortgage-application-document-view .fp-mortgage-application-document {
  padding: 10px 0 0;
  display: flex;
}

.fp-mortgage-application-document-view .fp-mortgage-application-document p {
  font-size: 14px;
  font-weight: 600;
  margin: 0 8px;
  color: #555555;
  align-items: center;
}

.fp-mortgage-application-document-view .fp-divider {
  background: #f0f4f7;
  width: 1px;
  line-height: 1.5;
  height: 35px;
  margin: auto 0px 10px;
}

.fp-mortgage-application-document-view
  .fp-mortgage-application-document
  .fp-badge {
  padding: 3px 5px;
  height: 15px;
  border-radius: 50%;
  width: 15px;
  font-size: 9px;
  left: 30px;
  top: 90%;
}

.fp-mortgage-application-document-view .fp-mortgage-application-view {
  display: flex;
  padding: 10px 0 0;
}

.fp-mortgage-application-document-view .fp-mortgage-application-view:hover {
  cursor: pointer;
}

.fp-mortgage-application-document-view .fp-mortgage-application-view p {
  font-size: 14px;
  font-weight: 600;
  margin: 0 8px;
  color: #555555;
  align-items: center;
}

.fp-mortgage-application-document-view .fp-mortgage-application-view a {
  color: #555555;
  text-transform: none;
}

.fp-network-blank-result {
  margin: 40px auto;
  opacity: 0.3;
  list-style: none;
  text-align: center;
}

.color-theme {
  color: #0073f0 !important;
  font-weight: 800 !important;
}

.fp-application-blank-display {
  height: 350px;
  border: 1px solid rgb(0, 115, 240, 0.18);
  width: 400px;
  border-radius: 10px;
}
