/* .show-on-hover:hover > ul.dropdown-menu {
  display: block;
} */

.dropdown.show:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: 1;
}

.dropdown-divider {
  margin: 0;
}

.fp-dashboard-bg-theme {
  left: -65px !important;
  top: -5px !important;
}
