#fp-mortgage-application {
  background-color: #ffffff;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.fp-mortgage-application {
  padding: 0px;
}

#fp-mortgage-application .fp-mortgage-landing-page-with-nhanla {
  /* margin-top: 80px; */
  padding: 120px 0px;
  /* max-width: 800px;  */
}

.fp-mortgage-landing-page-with-nhanla .fp-mortgage-application-nhanla {
  width: 100px;
  height: 100px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  margin: 20px auto;
}

.fp-mortgage-landing-page-with-nhanla h2 {
  color: #2e2e2e;
  text-align: left;
  font-size: 20px;
  font-weight: 800;
  margin: 20px auto;
  line-height: 1.5;
}

.fp-mortgage-landing-page-with-nhanla p {
  font-size: 16px;
  color: #2e2e2e;
  margin-bottom: 11px;
  margin-top: 5px;
  font-weight: 400;
}

.fp-mortgage-landing-page-with-nhanla .fp-mortgage-application-criteria {
  margin: 10px 0 0;
}

.fp-mortgage-landing-page-with-nhanla .fp-mortgage-application-criteria li {
  padding: 6px 0;
  color: #00b1ab;
  font-size: 28px;
  line-height: 23px;
  margin: 10px 0 0;
  font-weight: 400;
}

.fp-mortgage-landing-page-with-nhanla
  .fp-mortgage-application-criteria
  li
  span {
  color: #2e2e2e;
  font-size: 16px;
  vertical-align: bottom;
}

.fp-mortgage-landing-page-with-nhanla .fp-mortgage-application-criteria li a {
  text-decoration: none;
}

.fp-mortgage-landing-page-with-nhanla .fp-lets-begin-nhanla-button-wrapper {
  display: flex;
  justify-content: center;
}

/* .fp-pattern-overlay-footer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../Resource/header-bg-pattern-copy.png);
  background-size: cover;
  background-position: 50%;
  top: 0%;
} */

/* Personal Info Form */

.fp-mortgage-application .fp-personal-info-form {
  /* margin-top: 20px; */
  /* padding-top: 35px; */
  /* max-width: 700px; */
}

.fp-personal-info-form span {
  font-size: 13px;
  color: #2e2e2e;
  font-weight: 400;
}

.fp-personal-info-form h2 {
  color: #2e2e2e;
  text-align: left;
  font-size: 20px;
  font-weight: 800;
  margin: 20px auto;
}

.fp-personal-info-form input,
.fp-personal-info-form select {
  background-color: #fff;
  border: 1px solid #ada7a7;
  height: 42px;
  line-height: 17px;
  padding: 0 16px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #969292;
  font-size: 14px;
}

.fp-personal-info-form input:focus,
.fp-personal-info-form select:focus {
  outline: 0;
  box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}

.fp-personal-info-form input::placeholder {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.6;
}

.fp-personal-info-form label {
  font-size: 15px;
  color: #2e2e2e;
  margin-bottom: 11px;
  margin-top: 5px;
  font-weight: 200;
}

.input-group-append {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fp-home-type-checkbox {
  /* position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%; */
  /* transform: translate(-50%, -50%) scale(2, 2); */
  display: flex;
}

.fp-home-type-checkbox input {
  display: none;
}

.fp-home-type-checkbox label {
  font-size: 15px;
  color: #2e2e2e;
  display: inline-block;
  padding: 0 0 0 20px;
  margin-top: 2px;
}
.fp-home-type-checkbox label:before {
  content: "";
  display: inline-block;
  background: #00b1ab;
  position: absolute;
  left: 0;
  top: 5px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 80%;
  box-shadow: 0px 1px 5px rgb(0, 177, 171, 0.502);

  box-sizing: border-box;
  border: 10px solid #fff;
  transition: border 0.3s ease;
}

.fp-home-type-checkbox input:checked + label:before {
  border-color: #fff;
  border-width: 3px;
}
.fp-home-type-checkbox input + label {
  transition: color 0.7s ease;
}
.fp-home-type-checkbox input:checked + label {
  color: #00b1ab;
}

.fp-personal-info-form .fp-child-name {
  border-bottom-right-radius: 0%;
  border-top-right-radius: 0%;
}

.fp-personal-info-form .fp-child-age {
  border-radius: 0%;
}

.fp-personal-info-form .fp-child-relationship {
  border-bottom-left-radius: 0%;
  border-top-left-radius: 0%;
}

.fp-mortgage-application-preview {
  padding: 60px 0;
}

.fp-mortgage-landing-preview-card::before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -9px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: inherit;
  /* color: #2e2e2e; */
  color: #00b1ab;
  padding: 0 5px;
}

.fp-mortgage-landing-preview-card {
  font-size: 20px;
  padding: 20px;
  background-color: #fff;
  /* border: 1px solid #2e2e2e29; */
  border: 1px solid rgb(0, 177, 171, 1.191);
  position: relative;
  border-radius: 5px;
}

.fp-mortgage-application-preview .fp-mortgage-landing-preview-card span {
  color: #2e2e2e;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fp-mortgage-application-preview .fp-mortgage-landing-preview-card span:hover {
  cursor: pointer;
}

.fp-mortgage-application-preview span.fp-mortgage-application-action-btn {
  background-color: #00b1ab;
  border-color: #00b1ab;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.fp-mortgage-application-preview p {
  font-size: 15px;
  text-align: left;
  font-weight: 300;
  color: #7a7a7a;
}

.rtable {
  /* display: inline-block; */
  vertical-align: top;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 20px;
}

.rtable {
  -webkit-overflow-scrolling: touch;
  background: -webkit-radial-gradient(
        left ellipse,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      )
      0 center,
    -webkit-radial-gradient(
        right ellipse,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      ) 100% center;
  background: radial-gradient(
        ellipse at left,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      )
      0 center,
    radial-gradient(
        ellipse at right,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 75%
      )
      100% center;
  background-size: 10px 100%, 10px 100%;
  background-attachment: scroll, scroll;
  background-repeat: no-repeat;
}

.rtable td:first-child {
  background-image: -webkit-linear-gradient(
    left,
    white 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: linear-gradient(
    to right,
    white 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 20px 100%;
}

.rtable td:last-child {
  background-image: -webkit-linear-gradient(
    right,
    white 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: linear-gradient(
    to left,
    white 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 20px 100%;
}

.rtable th {
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
  background: #f3f4f5;
}

.rtable th,
.rtable td {
  padding: 6px 12px;
  border: 1px solid #d9d7ce;
  font-size: 13px;
}

.fp-mortgage-application-continue-button {
  background-color: #0143ba;
  border: 1px solid #0143ba;
  padding: 10px 80px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  float: right;
  font-size: 13px;
  margin-top: 20px;
}

.employer-group, .other-employments-wrapper {
  position: relative;
}

.employer-group .datalist-wrapper, .other-employments-wrapper .datalist-wrapper {
  position: absolute;
  width: 100%;
  max-height: 120px;
  overflow: auto;
  top: 80px;
  background: #fff;
  z-index: 2;
  border: 1px solid #ccc;
}

.employer-group input ~ div.datalist-wrapper div#companies > div,
.other-employments-wrapper input ~ div.datalist-wrapper > div {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
}

.employer-group input ~ div.datalist-wrapper div#companies > div:hover,
.other-employments-wrapper input ~ div.datalist-wrapper div#companies > div:hover {
  background: #ccc;
}

.fp-mortgage-application-preview-printer {
  float: right;
  color: #2e2e2e;
}

.fp-mortgage-application-preview-printer:hover {
  cursor: pointer;
}

@media print {
  .no-print {
    display: none;
  }

  .fp-mortgage-landing-preview-card[data-label='Financial Information'] {
    margin-top: 250px;
  }
}