#fp-mortgage-application-about-us-page {
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: #fdfdfd;
}

#fp-mortgage-application-about-us-page
  .fp-mortgage-application-about-us-page-wrapper {
  padding: 60px 0;
}

.fp-mortgage-application-about-us-page-wrapper h4 {
  padding-top: 40px;
  font-weight: 800;
  font-size: 25px;
}

.fp-mortgage-application-about-us-page-left
  .fp-mortgage-application-about-us-page-left-phone-display {
  background-image: url("../Resource/fp-about-us-mobile-tablet-display.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 450px;
}

/* .fp-mortgage-application-about-us-page-left p {
  text-align: left;
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  padding-right: 40px;
} */

.fp-mortgage-application-about-us-page-right {
  background: rgb(189, 243, 241, 0.309);
  border-radius: 10px;
  padding: 20px;
  /* margin-top: 30px; */
}
.fp-mortgage-application-about-us-page-right
  .fp-mortgage-application-about-us-page-right-wrapper
  h2 {
  font-size: 22px;
  font-weight: 800;
  padding: 0px 20px;
  margin: 10px 0;
}

.fp-mortgage-application-about-us-page-right-wrapper-text ol {
  font-size: 15.5px;
}

.fp-mortgage-application-about-us-page-right-wrapper-text ol li {
  margin-top: 14px;
}
.fp-mortgage-application-about-us-page-support-info-location {
  padding: 20px 0;
}

.fp-mortgage-application-about-us-page-support-info-location
  .fp-mortgage-application-about-us-page-support-info
  h4 {
  padding-top: 30px;
  font-weight: 800;
  font-size: 25px;
}
.fp-mortgage-application-about-us-page-support-info-location
  .fp-mortgage-application-about-us-page-support-info
  ol {
  font-size: 15.5px;
  padding-right: 22px;
}
.fp-mortgage-application-about-us-page-support-info-location
  .fp-mortgage-application-about-us-page-support-info
  ol
  li {
  margin: 10px 0;
  text-align: left;
}

.fp-mortgage-application-about-us-page-header-navigation
  .fp-mortgage-application-about-us-page-header-navigation-link {
  margin-top: 80px;
}
.fp-mortgage-application-about-us-page-header-navigation-link ul {
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  list-style: none;
}

.fp-mortgage-application-about-us-page-header-navigation-link ul li {
  margin: 0 20px;
  /* margin-left: 20px; */
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #ffffff;
  /* padding-right: 0.5rem;
  padding-left: 0.5rem; */
}

#fp-mortgage-application-about-us-page
  .fp-mortgage-application-about-us-page-content-wrapper {
  /* padding: 180px 0; */
  background: #00b1ab;
}

.fp-mortgage-application-about-us-page-content-wrapper
  .fp-mortgage-application-about-us-page-content-wrapper-img-bg {
  background-image: url("../Resource/fp-about-us.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  mix-blend-mode: hard-light;
  width: 100%;
  height: 100vh;
}

.fp-mortgage-application-about-us-page-content-text-overlay {
  background: #ffffff;
  padding: 60px 80px;
  position: absolute;
  background-image: url("../Resource/fp-faqs-pattern.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  /* top: 70px; */
}

.fp-mortgage-application-about-us-page-content-text-overlay h2 {
  text-align: center;
  font-size: 55px;
  font-weight: 600;
  padding: 0 80px;
  color: #022f8f;
  line-height: 65px;
}

.fp-mortgage-application-about-us-page-content-text-overlay p {
  text-align: left;
  font-size: 20px;
  margin: 30px auto;
  font-weight: 700;
  /* padding: 0 80px; */
}

/* #fp-mortgage-application-about-us-page
  .fp-mortgage-application-about-us-page-header-content {
  padding: 100px 0 80px;
  background: #ffffff;
} */

/* .fp-mortgage-application-about-us-page-header-content-left
  .fp-mortgage-application-about-us-page-header-content-left-img {
  background: url("../Resource/heroslide_sample_slide.png");
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.fp-mortgage-application-about-us-page-header-content-left
  .fp-mortgage-application-about-us-page-header-content-left-overlay {
  padding: 20px;
  background: #00b1ab;
  top: 0px;
  bottom: 0;
  left: 15px;
  position: absolute;
  height: 220px;
  width: 220px;
  border-radius: 5px;
}

.fp-mortgage-application-about-us-page-header-content-left-overlay span {
  color: #ffffff;
  font-size: 20px;
}

.fp-mortgage-application-about-us-page-header-content-left-overlay h2 {
  color: #ffffff;
  font-size: 25px;
  max-width: 150px;
  font-weight: 800;
  margin-top: 10px;
  line-height: 35px;
  text-transform: capitalize;
} */

#fp-mortgage-application-about-us-page
  .fp-mortgage-application-about-us-page-header-text {
  background: #00b1ab;
  padding: 120px 0 60px;
}

.fp-mortgage-application-about-us-page-header-text-line-pattern {
  background-image: url("../Resource/fp-landing-rates-line-pattern.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(-19.56deg);
  z-index: 200;
  background-position: 50%;
  top: 22%;
  width: 283px;
  height: 283px;
  left: -50px;
  opacity: 0.25;
}

.fp-mortgage-application-about-us-page-header-text h2 {
  text-align: center;
  padding: 0 40px;
  font-size: 55px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 20px;
  /* text-transform: capitalize; */
}

.fp-mortgage-application-about-us-page-header-text p {
  text-align: center;
  padding: 0 40px;
  font-size: 22px;
  line-height: 36px;
  margin-top: 20px;
  color: #ffffff;
  font-weight: 200;
}

.fp-mortgage-application-about-us-page-header-middle-content {
  background: #ffffff;
  padding: 50px 0 80px;
}

.fp-mortgage-application-about-us-page-header-middle-content h2 {
  font-size: 35px;
  text-align: left;
  font-weight: 700;
}

.fp-mortgage-application-about-us-page-header-middle-content span {
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  font-style: italic;
  /* margin-bottom: 20px; */
}

.fp-mortgage-application-about-us-page-header-middle-content p {
  font-size: 17px;
  text-align: left;
  margin: 20px auto;
}

.fp-mortgage-application-about-us-page-header-middle-content-lower-text {
  border-top: 1px dotted #121212;
}
