.fp-landing-faqs-contents-header-wrapper {
  padding: 80px 0;
  background: #1c2963;
}
.fp-landing-faqs-contents-header-wrapper h2 {
  margin: 30px auto 0;
  color: #ffffff;
  font-size: 45px;
  text-transform: capitalize;
  font-weight: 900;
  line-height: 1.15;
  text-shadow: 0px 1px 0 rgba(0, 0, 0, 0.9);
}
#fp-landing-faqs .fp-landing-faqs-circle-pattern {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  /* mix-blend-mode: multiply; */
  width: 100%;
  height: 240px;
}

.fp-landing-faqs .fp-landing-faqs-contents-wrapper.faqs {
  padding: 30px 0;
}
