.fp-mortgage-application-lender-info-wrapper
  .fp-mortgage-application-lender-info {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
}

.fp-mortgage-application-lender-info-wrapper
  .fp-mortgage-application-lender-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 2px dotted #f0f4f7;
}

.fp-mortgage-application-lender-content .fp-mortgage-application-lender-icon {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50px;
  width: 120px;
  left: 0;
  /* margin-bottom: 10px; */
}

.fp-mortgage-application-lender-content p {
  font-size: 13px;
  color: #555555;
  text-align: left;
  font-weight: 500;
}
.fp-mortgage-lender-company-info {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.fp-mortgage-lender-company-info h2 {
  font-size: 11px;
  color: #555555;
  font-weight: 700;
  text-transform: uppercase;
}

.fp-mortgage-lender-company-info .fp-badge-success {
  padding: 5px 10px 0;
  background: #f0f4f7;
  color: #54af19;
  font-weight: 700;
  font-size: 10px;
  height: 20px;
}
.fp-mortgage-lender-company-info .fp-badge {
  border-radius: 50%;
  font-size: 9px;
  margin: auto 10px;
}

.fp-mortgage-lender-company-info-wrap {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-email,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-phone,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-address {
  margin: auto 10px;
}

.fp-mortgage-lender-company-info-icon {
  margin-top: 12px;
}

.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-email span,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-phone span,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-address span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}

.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-email h4,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-phone h4,
.fp-mortgage-lender-company-info-wrap .fp-mortgage-lender-company-address h4 {
  font-size: 14px;
  color: #555555;
  font-weight: 500;
  line-height: 1.5;
}

.fp-mortgage-lender-company-info-wrap.fp-border-line {
  border-bottom: 2px dotted #f0f4f7;
  padding-bottom: 10px;
}

.fp-mortgage-contact-personal-info h2 {
  font-size: 11px;
  color: #555555;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}
