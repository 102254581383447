#fp-nhf-application {
  background-color: #ffffff;
  /* overflow: hidden; */
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.fp-nhf-affordability-eligibility-application-form {
  padding-top: 100px;
}

.fp-nhf-affordability-eligibility-application-form h2 {
  color: #2e2e2e;
  text-align: left;
  font-size: 35px;
  font-weight: 800;
}

.fp-nhf-affordability-eligibility-application-form p {
  color: #2e2e2e;
  text-align: left;
  line-height: 1.5;
  font-size: 17px;
  font-weight: 200;
}

.fp-nhf-affordability-eligibility-application-form label {
  font-size: 17px;
  color: #2e2e2e;
  margin-bottom: 11px;
  margin-top: 5px;
  font-weight: 200;
}

.fp-nhf-affordability-eligibility-application-form input {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 45px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}

.too-old-option {
  margin-top: 20px;
  margin-bottom: -30px;
}
.too-old-option p {
  font-size: 15px;
  font-weight: 300;
  /* color: #ff0000; */
}

.fp-nhf-affordability-eligibility-application-form .bg-theme-button-group {
  padding: 0 33px;
}

.fp-nhf-affordability-eligibility-application-form .form-control::placeholder {
  color: #c0ccda;
}

.fp-nhf-affordability-eligibility-application-form
  .fp-affordability-test-application-form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  padding-bottom: 30px;
}

.fp-nhf-affordability-eligibility-application-sidebar {
  background: #00b1ab;
  position: fixed;
  left: 42%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.fp-nhf-affordability-eligibility-application-sidebar-wrapper {
  margin-top: 70px;
  padding: 40px 25px 0px 45px;
}

.fp-nhf-affordability-eligibility-application-sidebar-wrapper
  .fp-affordability-test-loanable-amount
  span,
.fp-nhf-affordability-eligibility-application-sidebar-wrapper
  .fp-affordability-test-monthly-repayment
  span {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
}

.fp-nhf-affordability-eligibility-application-sidebar-wrapper
  .fp-affordability-test-loanable-amount
  h2,
.fp-nhf-affordability-eligibility-application-sidebar-wrapper
  .fp-affordability-test-monthly-repayment
  h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;
}

.fp-nhf-affordability-eligibility-application-sidebar-wrapper hr {
  border: 0;
  height: 1px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.75)),
    color-stop(100%, hsla(0, 0%, 100%, 0))
  );
  margin: 20px;
}

/* Other Modal Styles */
.fp-affordability-test-contact-us-content {
  background: #00b1ab;
  /* padding: 20px; */
  padding: 0 20px 10px;
}

.fp-affordability-test-contact-us-content-wrapper .fp-not-too-old {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  position: absolute;
  background-blend-mode: multiply;
  /* mix-blend-mode: hard-light; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
}

.fp-affordability-test-contact-us-content p {
  text-align: center;
  color: #d3d3d3;
  font-weight: 600;
  font-size: 12px;
}

.fp-affordability-test-contact-us-content .fp-nhf-affordability-info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.fp-nhf-affordability-info .fp-other-info-required.border-line-right::after {
  content: "";
  position: absolute;
  background-color: #e5e1e1;
  width: 1px;
  height: 12%;
  bottom: 0;
  top: 84%;
  margin-left: 170px;
  opacity: 0.3;
}

.fp-affordability-test-contact-us-content .fp-other-info-required span {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.fp-affordability-test-contact-us-content .fp-other-info-required h4 {
  color: #e5e5e5;
  /* font-size: 14px; */
  line-height: 20px;
}

.fp-affordability-test-contact-us-content-wrapper {
  padding: 30px;
}

.fp-not-too-old-button {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 20px !important;
  margin-top: 20px;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  width: 100%;
}
