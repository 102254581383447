.fp-mortgage-breadcrumb {
  background: rgb(248, 249, 254);
  display: flex;
}

.fp-mortgage-breadcrumb .fp-mortgage-breadcrumb-icon {
  margin: 25px 0px 0 40px;
}
.fp-mortgage-breadcrumb p {
  margin: 24px 15px;
  color: #8c8c8c;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  font-style: italic;
}

.fp-mortgage-breadcrumb.with-button {
  display: flex;
  justify-content: space-between;
}

.fp-mortgage-breadcrumb-right h4 {
  font-weight: 500;
  margin: 17px 0;
  padding: 20px;
  color: #828282;
  font-size: 17px;
  font-style: italic;
}

.fp-mortgage-breadcrumb-left {
  display: flex;
  margin: auto 15px;
}

.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-open-applications,
.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-approved-applications {
  border: 2px solid #dfdfe0;
  border-radius: 6px;
  padding: 2px 17px;
  margin: 20px 0;
}
.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-open-applications {
  margin-right: 20px;
}

.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-open-applications span,
.fp-mortgage-breadcrumb-left
  .fp-mortgage-breadcrumb-approved-applications
  span {
  font-size: 15px;
  color: #828282;
  font-weight: 600;
}

.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-open-applications h4,
.fp-mortgage-breadcrumb-left .fp-mortgage-breadcrumb-approved-applications h4 {
  font-size: 12px;
  color: #828282;
}
