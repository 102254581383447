.fp-dashboard-chat-message-actions.filter {
  background-color: #f0f4f7;
  border: 1px solid #f0f4f7;
  color: #8c8c8c;
}

.fp-customer-search-icon {
  position: absolute;
  left: 6%;
  top: 21%;
  z-index: 100;
}

.form-control.fp-customer-search {
  background: #f0f4f7;
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #00b1ab;
}

.responsive-table .col-1 {
  flex-basis: 10%;
}
.responsive-table .col-2 {
  flex-basis: 15%;
}
.responsive-table .col-3 {
  flex-basis: 25%;
}
.responsive-table .col-4 {
  flex-basis: 25%;
}

/* .fp-mortgage-applications-list-wrapper .responsive-table {
  padding-right: 20px;
  padding-left: 0px;
} */

.responsive-table .table-row {
  background-color: #f0f4f7;
  color: #555555;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  padding: 0;
}
