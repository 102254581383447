.fp-mortgage-application-loan-info-display {
  padding: 30px 30px;
  background: #ffffff;
  border-radius: 10px;
}

.fp-mortgage-application-loan-info-display
  .fp-mortgage-application-reference-no-loan-status {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 2px dotted #f0f4f7;
}

.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-date {
  margin: 0 180px 0px 0px;
  /* bottom: 0; */
}

.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-reference-no
  span,
.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-date
  span,
.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-status
  span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}

.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-reference-no
  h4,
.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-date
  h4 {
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin-top: 3px;
}

.fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-status
  h4 {
  align-items: center;
  padding: 6px 20px;
  /* background: #e6f3dd;
  color: #54af19; */
  font-weight: 600;
  border-radius: 0.25rem;
  font-size: 75%;
  line-height: 1;
}

.fp-mortgage-application-status.pending h4 {
  background: #cce1f6;
  color: #0177f3;
}

.fp-mortgage-application-status.approved h4 {
  background: #d9ead6;
  color: #54af19;
}

.fp-mortgage-application-status.rejected h4 {
  background: #e8d0d2;
  color: #ba0000;
}

.fp-mortgage-application-status.in-review h4,
.fp-mortgage-application-status.verifying h4 {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.fp-mortgage-application-status.submitted h4 {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.fp-mortgage-loan-amount-repayment-interest-rate {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0;
  border-bottom: 2px dotted #f0f4f7;
}

.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-amount-wrap
  h2 {
  font-size: 30px;
  font-weight: 600;
  color: #54af19;
  margin-bottom: -4px;
}

.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-amount-wrap
  h2::before {
  content: "₦";
  margin-right: 10px;
}

.fp-mortgage-loan-amount-repayment-interest-rate .fp-mortgage-repayment h2,
.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-interest-rate
  h2 {
  font-size: 30px;
  color: #555555;
  font-weight: 600;
  margin-bottom: -4px;
}

.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-interest-rate
  h2::after {
  content: "%";
}

.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-amount-wrap
  span,
.fp-mortgage-loan-amount-repayment-interest-rate .fp-mortgage-repayment span,
.fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-interest-rate
  span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}

.fp-mortgage-loan-more-information {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px dotted #f0f4f7;
}

.fp-mortgage-loan-more-information .fp-mortgage-loan-type-application-wrap {
  display: flex;
  /* justify-content: right; */
  flex-direction: row;
  margin-bottom: 10px;
}

.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-type span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}

.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-type h4 {
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin-top: 3px;
}

.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-application-type {
  padding: 6px 20px;
  background: #bbbbbb;
  border-radius: 0.25rem;
  margin-left: 80px;
  height: 30px;
  opacity: 0.5;
}

.fp-mortgage-loan-type-application-wrap .fp-mortgage-loan-application-type p {
  color: #f5f5f5;
  font-size: 75%;
  font-weight: 700;
}

.fp-mortgage-loan-more-information-right
  .fp-mortgage-loan-property-address-wrap
  span {
  font-size: 12px;
  color: #bbbbbb;
  font-weight: 800;
}

.fp-mortgage-loan-more-information-right
  .fp-mortgage-loan-property-address-wrap
  h4 {
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin: 5px auto;
  max-width: 360px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.fp-mortgage-loan-more-information-left {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0 10px;
}

.fp-mortgage-loan-more-information-left .fp-mortgage-loan-requirement-status {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: -60px;
}

.fp-mortgage-loan-more-information-left
  .fp-mortgage-loan-requirement-status
  h4 {
  width: 100px;
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin: auto 10px;
}

.fp-mortgage-loan-documents-uploads-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 0;
}

.fp-mortgage-loan-documents-uploads-actions
  .fp-mortgage-loan-documents-uploads-actions-right {
  display: flex;
  justify-content: space-between;
}

.fp-mortgage-loan-documents-uploads-actions
  .fp-mortgage-loan-documents-uploads:hover {
  cursor: pointer;
}

.fp-mortgage-loan-documents-uploads-actions-right
  .fp-mortgage-loan-documents-uploads {
  /* display: flex; */
  margin-right: 20px;
}

.fp-mortgage-loan-documents-uploads .fp-document-badge {
  height: 15px;
  padding: 3px 8px;
  font-size: 8px;
  border-radius: 5px;
  align-items: center;
  margin-left: -20px;
  margin-top: 10px;
  font-weight: 800;
}

.fp-mortgage-loan-documents-uploads-actions-left {
  margin-top: 10px;
}
