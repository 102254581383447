.fp-mobile-navigation-toggle {
  display: none;
}

.fp-mortgage-search-form .stylish-input-group {
  border: none;
  height: 40px;
  width: 400px;
  padding-left: 85px;
}

.fp-mortgage-search-form .str-search-icon {
  margin: 6px 0px 0px 10px;
  position: absolute;
  z-index: 1000;
}

.str-search-icon .fp-icon-trim {
  width: 18px;
}

.fp-mortgage-search-form .stylish-input-group .form-control {
  border: none;
  box-shadow: none;
  border-radius: 0;
  background: #f0f4f7;
  padding: 0px 40px;
  color: #114411;
  font-size: 13px;
  height: 40px;
  border-radius: 5px;
}

.fp-mortgage-notification-chat-profile-wrapper {
  display: flex;
  align-items: center;
}

.fp-mortgage-notification-chat-profile-wrapper > div {
  margin: 0 12px;
}

.fp-mortgage-notification-chat-profile-wrapper .fp-mortgage-notification,
.fp-mortgage-notification-chat-profile-wrapper .fp-mortgage-chat {
  position: relative;
  margin-top: 10px;
}

.fp-mortgage-chat .fp-icon-trim,
.fp-mortgage-notification .fp-icon-trim {
  width: 20px;
}

.fp-mortgage-notification .nav-unread.badge,
.fp-mortgage-chat .nav-unread.badge {
  position: absolute;
  top: -6px;
  left: 21px;
  display: block !important;
  padding: 1px 3px !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
}
.fp-mortgage-notification .badge-pill,
.fp-mortgage-chat .badge-pill {
  border-radius: 50px;
}

.fp-mortgage-user-profile-wrapper {
  display: flex;
  align-items: center;
}

.fp-mortgage-notification-chat-profile-wrapper .fp-mortgage-user-details h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
  letter-spacing: 0.6px;
}

.fp-mortgage-notification-chat-profile-wrapper .fp-mortgage-user-details span {
  font-size: 11px;
  margin-top: 0;
  font-weight: 300;
  line-height: 1;
  float: right;
}

.fp-mortgage-notification-chat-profile-wrapper .fp-mortgage-user-avatar {
  position: relative;
  width: 38px;
  height: 38px;
  margin: 0 10px;
}

.fp-mortgage-user-avatar .fp-user-avatar-initial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00b1ab;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.fp-mortgage-user-avatar .rounded-circle {
  border-radius: 50% !important;
}

.dropdown-menu-arrow::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -7px;
  right: 28px;
  transform: rotate(45deg);
  border-top: 1px solid #eaedf1;
  border-left: 1px solid #eaedf1;
}

.dropdown-menu-arrow.dropdown-menu-right::before,
.dropdown-menu-arrow.dropdown-menu-right::after {
  left: auto;
  right: 12px;
}

.fp-mortgage-dropdown {
  position: absolute;
  will-change: transform;
  top: 0px;
  transform: translate3d(-137.6px, 44.2px, 0px);
}

.dropdown-item {
  color: #555555;
  font-size: 13px;
  /* max-width: 10rem; */
  padding: 10px 30px;
}
