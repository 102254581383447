/* .datepicker-wrapper {
  position: relative;
  display: flex;
} */

.datepicker-wrapper * {
  user-select: none;
}

/* .datepicker-wrapper .calendar-view-wrapper {
  position: absolute;
  top: 0px;
  z-index: 3;
} */

.datepicker-wrapper .calender-view-grand-wrapper {
  background-color: rgba(153, 153, 153, 0.667);
}

.datepicker-wrapper .calender-view-grand-wrapper.hide {
  display: none;
}
