.invalid-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-msg.hide {
  display: none;
}

/* form.was-validated input.form-control:valid ~ .invalid-msg {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
} */

#fp-eligibility-test-application {
  background-color: #ffffff;
  /* overflow: hidden; */
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.fp-eligibility-test-application {
  padding: 0px;
}
.fp-eligibility-test-sidebar {
  background: #00b1ab;
}

.fp-eligibility-test-application-form-wrapper.result {
  /* padding: 120px 25px 20px 45px; */
  padding: 120px 0;
}

.fp-eligibility-test-application-form-wrapper.other {
  padding: 100px 25px 20px 45px;
}

.fp-eligibility-test-application-form-wrapper.landing {
  margin-top: 110px;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-application-form-title {
  color: #2e2e2e;
  text-align: left;
  font-size: 35px;
  font-weight: 800;
  margin: 20px auto;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-application-form-subtitle {
  color: #2e2e2e;
  text-align: left;
  line-height: 1.7;
  font-size: 17px;
  font-weight: 200;
}
.fp-eligibility-test-application-form-wrapper .disabled {
  opacity: 0.4;
}

.fp-eligibility-test-application-form-wrapper .toggled {
  opacity: 1;
}

.fp-eligibility-test-application-form-wrapper input {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 45px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}

.fp-eligibility-test-application-form-wrapper input:focus {
  box-shadow: none !important;
}

.fp-eligibility-test-application-form-wrapper .fp-form-numbering {
  display: block;
  font-weight: 400;
  color: #141414;
  font-size: 13px;
  margin: 30px 0 0;
  opacity: 0.7;
}

.fp-eligibility-test-application-form-wrapper label {
  font-size: 15px;
  color: #495057;
  margin-bottom: 11px;
  margin-top: 5px;
  font-weight: 200;
}

.fp-eligibility-test-application-form-wrapper .fp-radio-input-wrapper {
  justify-content: space-evenly;
  /* margin-top: 20px; */
}

.fp-eligibility-test-application-form-wrapper
  .fp-radio-input-wrapper
  input[type="radio"] {
  display: none;
}

.fp-eligibility-test-application-form-wrapper
  .fp-radio-input-wrapper
  .fp-radio-wrapper {
  text-align: left;
  background: #ffffff;
  padding: 20px;
  /* margin-bottom: 30px; */
  border-radius: 6px;
  border: 1px solid #b9b7b7;
  transition: 0.4s;
  width: 100%;
}

.fp-radio-input-wrapper .fp-radio-wrapper:hover {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  cursor: pointer;
}

.fp-radio-input-wrapper input[type="radio"]:checked + .fp-radio-wrapper {
  background-color: transparent;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  border-color: #00b1ab;
}

.fp-radio-wrapper .icon {
  width: 40px;
  height: 40px;
  margin: 5px 0 10px 70px;
  transition: 0.4s;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.fp-eligibility-test-application-form-wrapper
  .fp-radio-input-wrapper
  input[type="radio"]:checked
  + label::after {
  color: #fff;
  border: 1px solid #00b1ab;
  content: "\2713";
  font-size: 15px;
  line-height: 18px;
  position: absolute;
  top: 20px;
  left: 87%;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  background: #00b1ab;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
}

.fp-eligibility-test-application-form-wrapper .fp-radio-input-wrapper h3 {
  font-size: 14px;
  font-weight: 200;
  /* margin-top: 30px; */
  color: #2e2e2e;
  /* line-height: 30px; */
  text-align: center;
  /* width: 200px; */
  margin: 0px auto;
  white-space: nowrap;
}

.fp-eligibility-test-application-form-wrapper .DayPickerInput {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.fp-eligibility-test-application-form-wrapper .DayPickerInput input:focus {
  box-shadow: none !important;
}

.fp-eligibility-test-application-form-button
  .fp-eligibility-test-continue-button {
  margin-top: 20px;
}

.fp-eligibility-test-application-form-button
  .fp-eligibility-test-continue-button:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-success-medal {
  width: 150px;
  height: 165px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fp-eligibility-test-application-form-wrapper .fp-eligibility-test-success-text,
.fp-eligibility-test-application-form-wrapper .fp-eligibility-test-error-text {
  color: #2e2e2e;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
}

.fp-eligibility-test-application-form-wrapper
  .fp-affordability-test-payment-summary {
  border: 1px solid #ccc;
  padding: 15px 50px;
  margin: 20px auto;
  border-radius: 4px;
  max-width: 510px;
  justify-content: space-between;
  display: flex;
}

.fp-affordability-test-payment-summary .fp-loan-limit::before {
  content: "";
  background-color: #ccc;
  position: absolute;
  width: 1px;
  height: 50px;
  top: 48%;
  left: 48%;
  display: block;
  z-index: 100;
}

.fp-affordability-test-payment-summary span {
  color: #2e2e2e;
  font-weight: 200;
}

.fp-affordability-test-payment-summary h4 {
  color: #2e2e2e;
  font-weight: 500;
  margin-top: 2px;
  font-size: 20px;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-success-text-subtitle {
  color: #2e2e2e;
  text-align: center;
  line-height: 1.7;
  max-width: 530px;
  /* margin: 20px 0 20px 80px; */
  font-size: 17px;
  font-weight: 400;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-application-button-wrapper {
  display: flex;
  margin-top: 50px;
  justify-content: space-evenly;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-warning-image {
  width: 150px;
  height: 150px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fp-eligibility-test-application-form-wrapper
  .fp-eligibility-test-error-text-subtitle {
  color: #2e2e2e;
  text-align: center !important;
  line-height: 1.7;
  width: 100%;
  margin: 20px 10px !important;
  font-size: 17px;
  font-weight: 400;
}

.fp-eligibility-test-application-form-wrapper .fp-error-container {
  /* list-style-type: circle; */
  max-width: 550px;
  margin: 30px 0 20px 10px;
  padding-left: 20%;
  /* margin: 35px 0 20px; */
}

.fp-eligibility-test-application-form-wrapper .fp-error-container li {
  padding: 6px 0;
  color: #00b1ab;
  font-size: 35px;
  line-height: 20px;
}

.fp-eligibility-test-application-form-wrapper .fp-error-container li span {
  color: #2e2e2e;
  font-size: 16px;
  vertical-align: bottom;
}
