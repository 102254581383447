.fp-save-result-button {
  background-color: transparent;
  border: 1px solid #00b1ab;
  padding: 10px 20px !important;
  margin-top: 5px;
  line-height: 1.5;
  border-radius: 5px;
  color: #00b1ab !important;
  font-weight: 600;
}

/* .fp-save-result-button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
} */

.fp-check-loan-availability {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 20px !important;
  margin-top: 5px;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  width: 85%;
}

.fp-check-loan-availability:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
  box-shadow: 0px 5px 16px 0px rgb(0, 0, 0, 0.45);
}

.fp-eligibility-test-continue-button {
  background-color: #00b1ab;
  border: 1px solid#00b1ab;
  padding: 10px 100px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  float: right;
  font-size: 13px;
}

.fp-eligibility-test-continue-button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
}

.fp-sidebar-login-button {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 10px 100px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #141414 !important;
  font-weight: 600;
  text-decoration: none !important;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  z-index: 400;
}

.fp-sidebar-login-button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
}

.fp-modal-login-button,
.fp-modal-create-account-button {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 80px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  text-transform: uppercase;
  width: 100%;
  font-size: 13px;
}

.fp-modal-login-button:hover,
.fp-modal-create-account-button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
}

.fp-modal-login-button:focus,
.fp-modal-create-account-button:focus {
  outline: 0;
  box-shadow: none !important;
}

.fp-multi-step-button-white {
  background-color: transparent;
  border: 1px solid #00b1ab;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #00b1ab !important;
  width: 15%;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
}

.fp-multi-step-button-theme {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  /* width: 15%; */
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
}

.fp-multi-step-button-theme,
.fp-multi-step-button-white,
.fp-eligibility-test-continue-button {
  box-shadow: none !important;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.fp-send-email-with-results {
  background-color: transparent;
  border: 1px solid #00b1ab;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #00b1ab !important;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
}

.fp-send-email-with-results:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
  /* box-shadow: 0px 10px 16px 0px rgba(1, 67, 186, 0.38); */
}

.fp-send-email-with-results:focus {
  box-shadow: none !important;
  outline: 0;
}

.fp-eligibility-apply-for-mortgage {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  /* box-shadow: 0px 10px 16px 0px rgba(1, 67, 186, 0.38); */
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
}

.fp-eligibility-apply-for-mortgage:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
}

.fp-eligibility-apply-for-mortgage:focus {
  /* box-shadow: none !important; */
  outline: 0;
}

/* Mortgage Application Form Landing Page  */

.fp-lets-begin-nhanla-button {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  width: 55%;
  /* box-shadow: 0px 10px 16px 0px rgba(1, 67, 186, 0.38); */
  /* box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28); */
  box-shadow: 0px 10px 16px 0px rgba(1, 67, 186, 0.18);
  margin-top: 30px;
}

.fp-lets-begin-nhanla-button:focus {
  outline: 0;
}

.fp-lets-begin-nhanla-button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #fff !important;
}

.fp-dashboard-mortgage-btn {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 15px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
  /* width: 55%; */
  box-shadow: 0px 10px 16px 0px rgb(0, 177, 171, 0.38);
}

.fp-dashboard-mortgage-btn:hover {
  background-color: #121212;
  border: 1px solid #121212;
  box-shadow: 0px 10px 16px 0px rgba(18, 18, 19, 0.38);
}

.fp-dashboard-profile-edit-btn {
  background-color: #d9eafd;
  border: 1px solid #d9eafd;
  padding: 5px 25px !important;
  /* line-height: 1.7; */
  border-radius: 0.25rem;
  color: #0073f0 !important;
  font-weight: 400;
  font-size: 16px;
  width: 44%;
}

.fp-dashboard-credit-score-btn {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 25px !important;
  line-height: 1.7;
  border-radius: 0.25rem;
  color: #555555;
  font-weight: 400;
  font-size: 13px;
  width: 55%;
  /* z-index: 1200; */
}

.fp-dashboard-credit-score-btn:hover {
  background-color: #121212;
  border: 1px solid #121212;
  color: #ffffff;
}

.fp-dashboard-more-actions {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 25px !important;
  line-height: 1.7;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  width: 55%;
}

.fp-dashboard-chat-message-actions {
  background-color: #bdd2e7;
  border: 1px solid #bdd2e7;
  padding: 10px 25px !important;
  line-height: 1.7;
  border-radius: 0.25rem;
  color: #0073f0;
  font-weight: 400;
  font-size: 13px;
  width: 55%;
}

.fp-update-account-details-button,
.fp-change-account-password-button {
  margin-top: 20px;
  background-color: #0143ba;
  border: 1px solid #0143ba;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  box-shadow: 0px 10px 16px 0px rgba(1, 67, 186, 0.28);
  font-size: 13px;
  transition: all 300ms ease 0s;
}

.fp-remove-account-button {
  margin-top: 20px;
  background: rgba(253, 47, 47, 0.1) none repeat scroll 0% 0%;
  border: 1px solid #fd2f2f;
  padding: 10px 30px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: rgb(23, 23, 23);
  font-weight: 600;
  text-decoration: none !important;
  font-size: 13px;
  transition: all 300ms ease 0s;
  box-shadow: 0px 10px 16px 0px rgb(252, 47, 47, 0.28);
}

.fp-mortgage-application-contact-us-form-submit-btn {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 25px !important;
  line-height: 1.7;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  /* width: 55%; */
  float: left;
}
.fp-mortgage-application-contact-us-form-submit-btn:hover {
  background-color: #000000;
  border: 1px solid #000000;
}
