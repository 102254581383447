.fp-nh-affordability-regular-affordability-suggestion-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgb(0, 0, 0, 0.7);
  width: 100%;
  overflow: hidden !important;
  outline: 0;
  height: 100vh;
}

/* .fp-nh-affordability-regular-affordability-suggestion-wrapper */
.fp-nh-affordability-regular-affordability-property-suggestion-content {
  /* overflow-x: scroll; */
  margin: 20px 0;
  overflow-y: hidden;
  flex-wrap: nowrap;
  display: flex;
  position: relative;
}

.fp-nh-affordability-regular-affordability-property-suggestion-list {
  background: #fff;
  /* padding: 10px 20px 0; */
  border-radius: 5px;
  width: 252px;
  margin: 0 10px 0 0;
  height: 362px;
  margin-right: 20px;
  /* border: 1px solid #e8e9ea; */
  transition: box-shadow 0.3s ease;
  /* margin-top: 30px; */
  z-index: 100;
}

.fp-nh-affordability-regular-affordability-property-suggestion-list
  .fp-nh-affordability-regular-affordability-property-suggestion-img {
  height: 116px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-position: 50%;
  background-size: cover;
  position: relative !important;
  background-repeat: no-repeat;
  width: 100%;
}
.fp-nh-affordability-regular-affordability-property-property-info {
  position: relative;
  padding: 10px 20px 0;
  /* padding: 0 1rem !important; */
}

.fp-nh-affordability-regular-affordability-property-property-info h2 {
  color: #555;
  font-size: 16px;
  font-weight: 700;
  /* margin-top: 15px; */
}

.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-features {
  font-size: 11px;
  color: #555;
  margin-bottom: 8px;
}

.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-features
  img {
  height: 15px;
}

.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-name,
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-address {
  font-size: 10px;
  color: #999999;
  line-height: 1.5;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-finance-options {
  display: flex;
  justify-content: space-between;
  font-size: 9px;
  font-weight: 600;
  /* top: 17px;
    right: 10px; */
  border-top: 1px solid #f2f6f9;
  padding: 6px 0;
  margin-top: 10px;
}
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-finance-options
  .fp-finance
  h4,
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-finance-options
  .fp-property-status
  h4 {
  color: #898989;
  line-height: 2;
  font-size: 9px;
  margin: 0;
}
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-finance-options
  .fp-finance
  p {
  color: #57b40b !important;
  line-height: 1.5;
  font-weight: 600;
}

.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-finance-options
  .fp-property-status
  p {
  color: #333 !important;
  line-height: 1.5;
  font-weight: 600;
}
.fp-nh-affordability-regular-affordability-property-property-info
  .fp-property-property-verification {
  display: flex;
  justify-content: space-between;
  font-size: 9px;
  font-weight: 600;
  border-top: 1px solid #f2f6f9;
  margin-top: -10px;
  padding: 10px 0;
}

.fp-property-property-verification .fp-property-document-title,
.fp-property-property-verification .fp-property-building-permit {
  color: #747474;
  font-size: 9px;
  font-weight: 600;
  text-transform: capitalize;
}
.font-bold {
  font-weight: 900;
}

.fp-property-suggestion-button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.fp-property-suggestion-button .fp-property-suggestion-button-make-target {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 6px 12px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 10px;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
}

.fp-property-suggestion-button .fp-property-suggestion-button-view-more {
  background-color: transparent;
  border: 1px solid #00b1ab;
  padding: 6px 12px !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #00b1ab !important;
  font-weight: 600;
  font-size: 10px;
}

.fp-property-suggestion-cancel-button {
  margin: 0 auto;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #e7e5e5;
  padding: 0;
}
