#fp-affordability-test-application {
  background-color: #ffffff;
  /* overflow: hidden; */
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.hideform {
  display: none;
}

.fp-affordability-test-application {
  padding: 0px;
}

.fp-affordability-test-application-form.overlay::after,
.fp-nhf-affordability-eligibility-application-form.overlay::after {
  position: absolute;
  top: 0;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.9;
  background: #eaeaeabf;
  display: block;
}

.fp-affordability-test-application-display {
  background: #00b1ab;
}

.fp-affordability-test-application-form-wrapper {
  margin-top: 120px;
  /* padding: 40px 0px 0px; */
}

.fp-affordability-test-application-display-wrapper {
  margin-top: 90px;
  padding: 40px 25px 0px 45px;
}

.fp-affordability-test-application-form-wrapper
  .fp-affordability-test-application-form-title {
  color: #2e2e2e;
  text-align: left;
  font-size: 35px;
  font-weight: 800;
  /* width: 550px; */
  /* margin: 20px auto; */
}

.fp-affordability-test-application-form-wrapper
  .fp-affordability-test-application-form-subtitle {
  color: #2e2e2e;
  text-align: left;
  line-height: 1.5;
  /* max-width: 450px; */
  /* margin: 20px 80px; */
  font-size: 17px;
  font-weight: 200;
}

.fp-affordability-test-application-form-wrapper input {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 45px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}

.fp-affordability-test-application-form-wrapper input:focus {
  box-shadow: none !important;
}

.fp-affordability-test-application-form-wrapper label {
  font-size: 15px;
  color: #2e2e2e;
  margin-bottom: 11px;
  margin-top: 5px;
  font-weight: 200;
}
.fp-affordability-test-application-form-wrapper label sup {
  color: #f00000;
  font-size: 30px;
  top: 5px;
}

.fp-affordability-test-application-form-wrapper
  .fp-affordability-test-application-form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  padding-bottom: 30px;
  width: 100%;
}

.fp-affordability-test-application-display-wrapper
  .fp-affordability-test-loanable-amount
  span,
.fp-affordability-test-application-display-wrapper
  .fp-affordability-test-monthly-repayment
  span {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
}

.fp-affordability-test-application-display-wrapper
  .fp-affordability-test-loanable-amount
  h2,
.fp-affordability-test-application-display-wrapper
  .fp-affordability-test-monthly-repayment
  h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;
}

.fp-affordability-test-application-display-wrapper hr {
  border: 0;
  height: 1px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.75)),
    color-stop(100%, hsla(0, 0%, 100%, 0))
  );
  margin: 20px;
}
.fp-affordability-test-result-summary
  .fp-affordability-test-result-summary-text {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
}

.fp-affordability-test-result-summary
  .fp-affordability-test-result-summary-text
  h2 {
  color: #ffffff;
  min-width: 150px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}
.fp-affordability-test-result-summary
  .fp-affordability-test-result-summary-text
  p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  text-align: right;
  padding-left: 80px;
  padding-right: 30px;
}

.fp-affordability-test-result-summary .fp-affordability-test-result-bg-pattern {
  background: url("../Resource/header-bg-pattern-copy.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  /* z-index: 2000; */
  top: 120px;
}

.fp-affordability-test-result-summary .fp-affordability-test-property-display {
  width: 100%;
  height: 400px;
  /* overflow-y: scroll; */
}

.fp-affordability-test-result-summary
  .fp-affordability-test-property-display-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  z-index: 1000;
  position: relative;
}

.fp-affordability-test-property-display-wrapper .fp-property-image-preview {
  height: 100px;
  width: 150px;
  background-repeat: no-repeat;
  background-position: 50%;
  box-shadow: 0 4px 13px 0 hsla(0, 0%, 52.9%, 0.23);
  background-size: cover;
  border-radius: 5px;
}
.fp-property-price-range {
  color: #ffffff;
  text-align: right;
  margin-top: 8px;
}
.fp-property-price-range .fp-price-tag {
  font-size: 12px;
}
.fp-property-price-range h2 {
  font-size: 17px;
}

.fp-property-price-range .fp-property-link-view {
  color: #d9e3f5;
  border-bottom: 1px solid #d9e3f5;
  font-style: italic;
  font-size: 13px;
  text-decoration: none;
}

.fp-property-display-info {
  color: #ffffff;
  margin: 15px 0 0;
  padding-right: 50px;
}
.fp-property-display-info h2 {
  font-size: 14px;
  margin-bottom: 2px;
  text-transform: capitalize !important;
}

.fp-property-display-info span {
  font-size: 12px;
}

.fp-property-display-info .fp-property-home-amenity {
  font-size: 12px;
  margin-top: 6px;
}

.fp-property-display-info .fp-property-home-amenity img {
  height: 15px;
}

.fp-property-display-info .fp-property-home-amenity .fp-bath-icon {
  margin-left: 10px;
}
.fp-affordability-test-result-bottom-wrapper {
  padding: 20px;
}

.fp-affordability-test-result-bottom-wrapper
  .fp-affordability-test-result-bottom-pattern {
  background: url("../Resource/house-silhouette.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 95%;
  height: 40px;
  background-size: cover;
  background-position: 50%;
  z-index: 2000;
  top: 93%;
  left: 2%;
  opacity: 0.4;
}

.range {
  position: relative;
  margin-top: 6px !important;
}
.range input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  width: 100%;
  height: 22px;
  cursor: pointer;
  display: block;
  border: none !important;
}
.range input[type="range"]:focus {
  outline: none;
}
.range input[type="range"][disabled] {
  opacity: 0.3;
  cursor: default;
}
.range .rangeslider {
  position: relative;
  height: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.range .rangeslider:before {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #39f;
  border-radius: 100px;
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.range input::-webkit-slider-runnable-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  border-radius: 100px;
  margin: 11px 0;
}
.range input::-moz-range-track {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
  margin: 11px 0;
}

.range input::-ms-fill-lower,
.range input::-ms-fill-upper {
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  background: #e6e6e6;
  border-radius: 100px;
}

.range input::-webkit-slider-thumb {
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  border: 6px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #00b1ab;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 2px;
}
.range input::-moz-range-thumb {
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  border: 6px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #00b1ab;
  cursor: pointer;
  outline: none;
}

.range input::-moz-range-thumb:focus,
.range input::-webkit-slider-thumb:focus {
  outline: none !important;
}
.range input::-ms-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #333940;
  cursor: pointer;
  outline: 0;
}

.range input::-moz-range-thumb:focus {
  outline: none !important;
}
.range .rangeslider-thumb {
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  border: 6px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: #f00;
  cursor: pointer;
  position: absolute;
  touch-action: pan-x;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  will-change: left;
}
.range .range-output {
  position: absolute;
  /* left: 6px; */
  top: 6px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  user-select: none;
  touch-action: pan-y;
}
.range .range-output .output {
  display: block;
  position: absolute;
  height: 30px;
  padding: 6px 8px;
  /* -webkit-transform: translate(50%, -30%); */
  /* transform: translate(50%, -30%); */
  transform: translate(0%, 65%);
  background: #ffff;
  color: #55637d;
  border-radius: 2px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 13.5px;
  text-align: center;
  box-shadow: 0 4px 13px #ddd;
}

.select-css {
  display: block;
  font-size: 14px;
  height: 45px;
  line-height: 1.3;
  padding: 0 16px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  /* box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04); */
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #969292;
  outline: 0 !important;
}
.select-css:focus {
  border-color: #969292;
  box-shadow: none;
  color: #969292;
  outline: none !important;
}
.select-css option {
  font-weight: normal;
}

.form-control::placeholder {
  color: #969292;
  opacity: 0.8;
}

.result.fp-affordability-test-application-form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  padding-bottom: 30px;
  width: 100%;
}

.result.fp-affordability-test-application-form-buttons .fp-save-result-button {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 20px !important;
  margin-top: 5px;
  line-height: 1.5;
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 600;
}

.result.fp-affordability-test-application-form-buttons
  .fp-check-loan-availability {
  background-color: #000000;
  border: 1px solid #000000;
  padding: 10px 20px !important;
  margin-top: 5px;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  width: 85%;
  animation: changeOpacity 3.5s infinite;
}

.fp-mortgage-tooltips {
  /* margin-left: 80px; */
  /* float: right; */
}

.fp-mortgage-application-tooltip {
  position: relative;
  display: inline-block;
}

.fp-mortgage-application-tooltip .tooltiptext {
  visibility: hidden;
  background-color: #d9e3f5;
  color: #121212;
  text-align: center;
  padding: 10px 0;
  /* border-radius: 6px; */
  top: -30px;
  right: 20px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #00b1ab; */
  max-width: 200px;
  height: fit-content;
}

.fp-mortgage-application-tooltip:hover .tooltiptext {
  visibility: visible;
  font-size: 11px;
}

.fp-mortgage-application-mobile-loan-amount-summary {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 30;
  visibility: hidden;
}

.fp-mortgage-application-mobile-loan-amount-summary-content {
  background: #00b1ab;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.fp-mortgage-application-mobile-loan-amount-summary-content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.fp-mortgage-application-mobile-loan-amount {
  margin-top: 25px;
  padding: 0 30px;
}

.fp-mortgage-application-mobile-loan-amount-monthly-repayment {
  padding: 0 30px;
}

.fp-mortgage-application-mobile-loan-amount h4,
.fp-mortgage-application-mobile-loan-amount-monthly-repayment h4 {
  color: #ffffff;
  font-size: 17px;
  text-align: left;
}

.fp-mortgage-application-mobile-loan-amount h2,
.fp-mortgage-application-mobile-loan-amount-monthly-repayment h2 {
  text-align: left;
  font-size: 30px;
  color: #ffffff;
}

.fp-mortgage-application-mobile-loan-amount-summary-content-wrapper hr {
  border: 0;
  height: 1px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.75)),
    color-stop(100%, hsla(0, 0%, 100%, 0))
  );
  margin: 20px;
}

.fp-mortgage-application-mobile-affordability-action-button {
  background: #fff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.fp-mortgage-application-mobile-affordability-action-button {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
}

.fp-mortgage-application-mobile-affordability-action-button
  .fp-save-mobile-result-button {
  background: #fff;
  border-radius: 5px;
  padding: 7px 20px;
  color: #00b1ab;
  border: 1px solid #00b1ab;
  font-weight: 800;
  height: 50px;
  margin-top: 13px;
}

.fp-mortgage-application-mobile-affordability-action-button
  .fp-check-mobile-loan-availability {
  background: #00b1ab;
  box-shadow: 0 10px 14px rgba(0, 177, 171, 0.28);
  border-radius: 5px;
  padding: 14px 30px;
  color: #fff;
  /* margin-right: 20px; */
  font-weight: 800;
  height: 50px;
  margin-top: 13px;
  border: none;
}
.fp-mortgage-application-mobile-property-suggestion {
  margin-top: 20px;
  padding: 0 30px;
}

.fp-mortgage-application-mobile-property-suggestion h2 {
  color: #ffffff;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}
.fp-mortgage-application-mobile-property-suggestion-wrapper {
  margin: 20px 0;
}

.fp-mortgage-application-mobile-property-suggestion-wrapper
  .fp-mortgage-application-mobile-property-suggestion-list {
  border-top: 1px dotted #ffffff;
  display: flex;
  justify-content: space-between;
}

.fp-mortgage-application-mobile-property-suggestion-list h2 {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  margin: 20px auto;
}
