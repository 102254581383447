#fp-mortgage-application-contact-us-top-info {
  /* background-color: #00b1ab;
  height: 500px; */
  /* transform: skew(0, -12deg); */
  background-color: #1c2963;
  padding: 20px 0;
}

#fp-mortgage-application-contact-us-top-info
  .fp-mortgage-application-contact-us-top-info-pattern {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  /* mix-blend-mode: multiply; */
  width: 100%;
  height: 350px;
}

#fp-mortgage-application-contact-us-top-info
  .fp-mortgage-application-contact-us-top-info-wrapper {
  padding: 60px 0 0;
}

.fp-mortgage-application-contact-us-top-info-wrapper h4 {
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.fp-mortgage-application-contact-us-top-info-wrapper h2 {
  /* margin-top: 50px; */
  font-size: 80px;
  line-height: 75px;
  padding-bottom: 19px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin: 30px 0;
}

.fp-mortgage-application-contact-us-top-info-wrapper p {
  font-size: 15px;
  line-height: 26px;
  padding: 0;
  color: #fff;
  letter-spacing: 0.1px;
}

#fp-mortgage-application-contact-us-form-features {
  background: #ffffff;
  padding: 80px 0 50px;
}

#fp-mortgage-application-contact-us-form-features
  .fp-mortgage-application-contact-us-form {
  padding: 40px 55px;
  margin-top: -190px;
  max-width: 840px;
  border-radius: 4px;
  box-shadow: 0 28px 65px 0 hsla(0, 0%, 73.7%, 0.25);
  background-color: #fff;
}

.fp-mortgage-application-contact-us-form-text h2 {
  /* color: #121212; */
  color: #424770;
  font-weight: 700;
}

.fp-mortgage-application-contact-us-form-text p {
  font-weight: 700;
  color: #424770;
  font-size: 28px;
  margin-bottom: 40px;
  line-height: 30px;
}

.fp-mortgage-application-contact-us-form label {
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 15px;
  color: #2e2e2e;
  /* color: #424770; */
}

.fp-mortgage-application-contact-us-form input {
  width: 100%;
  box-shadow: 0 28px 65px 0 hsla(0, 0%, 73.7%, 0.25);
  margin-bottom: 18px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 50px;
  line-height: 17px;
  padding: 0 16px;
}

.fp-mortgage-application-contact-us-form textarea {
  width: 100%;
  border: 1px solid #c5c5c5 !important;
  box-shadow: 0 28px 65px 0 hsla(0, 0%, 73.7%, 0.25);
  padding: 14px;
  margin-bottom: 18px;
  border-radius: 3px;
  background-color: #fff;
}

.fp-mortgage-application-contact-us-form input::placeholder,
.fp-mortgage-application-contact-us-form textarea::placeholder {
  font-size: 15px;
  color: #969292;
  opacity: 0.8;
}

.fp-mortgage-application-contact-us-form input:focus,
.fp-mortgage-application-contact-us-form textarea:focus {
  box-shadow: none;
  border-color: #00b1ab;
}

.fp-mortgage-application-contact-us-features h2 {
  color: #424770;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 35px;
  margin-right: 20px;
}

.fp-mortgage-application-contact-us-features-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.fp-mortgage-application-contact-us-features-wrapper
  .fp-mortgage-application-contact-us-features-list {
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
}

.fp-mortgage-application-contact-us-features-wrapper
  .fp-mortgage-application-contact-us-features-list-text {
  margin-left: 10px;
  margin-top: 10px;
}

.fp-mortgage-application-contact-us-features-list-text h4 {
  font-weight: 800;
  color: #424770;
  font-size: 20px;
}

#fp-mortgage-application-contact-us {
  background-color: #ffffff;
}

.fp-mortgage-application-contact-us-header-text {
  padding: 20px 0 40px;
}

.fp-mortgage-application-contact-us-header-text h2 {
  text-align: center;
  font-size: 35px;
  color: #121212;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;
}

.fp-mortgage-application-contact-us-header-text
  .fp-mortgage-application-contact-us-info {
  /* padding: 60px 80px; */
  margin: 40px auto;
}
.fp-mortgage-application-contact-us-options {
  padding-left: 40px;
  padding-right: 40px;
}

.fp-mortgage-application-contact-us-options:first-of-type {
  border-right: 1px solid #e6ebf1;
  border-bottom: none;
}

.fp-mortgage-application-contact-us-options h2 {
  font-weight: 800;
  font-size: 19px;
  line-height: 32px;
  /* color: #32325d; */
  color: #424770;
  text-align: center;
}

.fp-mortgage-application-contact-us-options p {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
  text-align: center;
}
