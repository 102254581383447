.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader-wrapper.hide {
  display: none;
}

.loader-wrapper .loader {
  height: 60px;
  width: 60px;
  border: 6px solid #dadada;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 2s ease-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
