.fp-dashboard-chat-message-actions.filter {
  background-color: #f0f4f7;
  border: 1px solid #f0f4f7;
  color: #8c8c8c;
}

.fp-customer-search-icons {
  position: absolute;
  left: 6%;
  top: 9%;
  z-index: 100;
}

.form-control.fp-customer-search {
  background: #f0f4f7;
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none;
}
