.fp-mortgage-blank-lender-wrapper {
  margin: 20px 40px;
  opacity: 0.1;
}

.fp-login-modal-close.lender {
  left: 20px;
}

.fp-modal-body-wrapper h4 {
  color: #828282;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
}

.fp-mortgage-lender-company-info-wrap.hidden {
  display: none;
}

.fp-mortgage-application-status.pending h4 {
  background: #cce1f6;
  color: #0177f3;
}

.fp-mortgage-application-status.approved h4 {
  background: #d9ead6;
  color: #54af19;
}

.fp-mortgage-application-status.rejected h4 {
  background: #e8d0d2;
  color: #ba0000;
}
