.fp-eligibility-test-sidebar {
  padding: 140px 25px 20px 45px;
  position: fixed;
  left: 59%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: #00b1ab;
}

.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-loanable-amount
  span,
.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-monthly-repayment
  span {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
}

.fp-eligibility-test-sidebar-wrapper .fp-affordability-test-loanable-amount h2,
.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-monthly-repayment
  h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 500;
  margin-top: 5px;
}

.fp-eligibility-test-sidebar-wrapper hr {
  border: 0;
  height: 1px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.75)),
    color-stop(100%, hsla(0, 0%, 100%, 0))
  );
  margin: 20px;
}
.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-result-summary-text {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
}

.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-result-summary-text
  h2 {
  color: #ffffff;
  min-width: 150px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}
.fp-eligibility-test-sidebar-wrapper
  .fp-affordability-test-result-summary-text
  p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  text-align: right;
  padding-left: 80px;
  padding-right: 30px;
}

.fp-eligibility-test-sidebar-text .fp-eligibility-test-sidebar-action-text {
  margin: 30px 0 0;
  padding: 0px 40px;
}

.fp-eligibility-test-sidebar-text .fp-eligibility-test-sidebar-action-text h2 {
  color: #ffffff;
  font-size: 37px;
  line-height: 42px;
  max-width: 250px;
  font-weight: 500;
}
.fp-eligibility-test-sidebar-text .fp-eligibility-test-sidebar-action-text p {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  /* opacity: 0.48; */
  line-height: 30px;
  margin: 20px 0px;
  max-width: 340px;
  padding-bottom: 30px;
}

.fp-eligibility-test-sidebar-text .fp-eligibility-test-sidebar-bg-pattern {
  background: url("../Resource/bg-pattern-sidebar.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 85%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  /* z-index: 2000; */
  top: -10px;
}
