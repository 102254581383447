.fp-mortgage-loan-documents-uploads .close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.fp-mortgage-loan-documents-uploads .close:hover,
.fp-mortgage-loan-documents-uploads .close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.fp-mortgage-loan-documents-uploads .images-wrapper {
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 0 auto;
}

.fp-mortgage-loan-documents-uploads .images-wrapper .image {
  width: 20%;
  margin: 0 10px;
}

.fp-mortgage-loan-documents-uploads .images-wrapper .image img {
  width: 100%;
}

.fp-mortgage-loan-documents-uploads .modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #222222cc;
  flex-direction: column;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.fp-mortgage-loan-documents-uploads .modal-content {
  position: relative;
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

.fp-mortgage-loan-documents-uploads .mySlide {
  margin-bottom: 50px;
  width: 60%;
  background: inherit;
  align-self: center;
}

.fp-mortgage-loan-documents-uploads .mySlide .numbertext {
  position: fixed;
}

.fp-mortgage-loan-documents-uploads .prev, .fp-mortgage-loan-documents-uploads .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position next button to the right */
.fp-mortgage-loan-documents-uploads .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see through */
.fp-mortgage-loan-documents-uploads .prev:hover,
.fp-mortgage-loan-documents-uploads .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.fp-mortgage-loan-documents-uploads .thumbnails {
  display: flex;
  height: 200px;
  margin: 0 auto;
  width: 70%;
}

.fp-mortgage-loan-documents-uploads .thumbnails .thumbnail {
  width: 200px;
  margin: 0 5px;
}

.fp-mortgage-loan-documents-uploads .caption-container {
  text-align: center;
  padding: 2px 16px;
  color: #fff;
}

.fp-mortgage-loan-documents-uploads img.demo {
  opacity: 0.6;
}

.fp-mortgage-loan-documents-uploads img.demo.active, .lightbox .demo:hover {
  opacity: 1;
}

.fp-mortgage-loan-documents-uploads img.hover-shadow {
  transition: 0.3s;
}

.fp-mortgage-loan-documents-uploads .hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
  0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fp-mortgage-loan-documents-uploads .download-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.fp-mortgage-loan-documents-uploads .download-btn {
  display: block;
  padding: 10px 20px;
  background: blue;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 10px;
}