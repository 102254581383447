.fp-mortgage-application-loan-value {
  padding: 40px 20px;
  background: #022f8f;
  border-radius: 10px;
}

.fp-mortgage-application-loan-value h2 {
  font-size: 30px;
  color: #ffffff;
  /* margin: auto 10px; */
  font-weight: 800;
}

.fp-mortgage-application-loan-value h2::before {
  content: "₦";
  margin-right: 10px;
}

.fp-mortgage-application-loan-value p {
  font-size: 15px;
  color: #ffffff;
  margin: auto 2px;
  text-align: left;
}

.fp-mortgage-application-loan-value
  .fp-mortgage-application-loan-value-pattern {
  background-image: url("../Resource/lenderIconValue.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 250px;
  width: 250px;
  left: 247px;
  bottom: 0;
  top: -6%;
  margin-bottom: 10px;
  position: absolute;
  background-blend-mode: color;
  clip: rect(10px, 118px, 155px, 0px);
  opacity: 0.8;
}
