.fp-mortgage-application-credit-score {
  padding: 30px 20px;
  background: #0073f0;
  border-radius: 10px;
}

.fp-mortgage-application-credit-score p {
  font-size: 12px;
  color: #ffffff;
  line-height: 1.5;
  font-weight: 700;
}

.fp-mortgage-application-credit-score h2 {
  font-size: 45px;
  color: #ffffff;
  font-weight: 800;
}

.fp-mortgage-application-credit-score
  .fp-mortgage-application-credit-score-icon {
  background-image: url(../Resource/WalletIcon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30%;
  width: 150px;
  height: 150px;
  position: absolute;
  left: 234px;
  bottom: 339px;
  background-blend-mode: color;
  clip: rect(0px, 128px, 185px, 0px);
  z-index: 100;
}

.fp-mortgage-application-settings-form {
  padding: 30px 30px;
  background: #ffffff;
  border-radius: 10px;
}

.fp-mortgage-application-settings-form h2 {
  font-size: 11px;
  color: #555555;
  font-weight: 700;
  text-transform: uppercase;
}

.fp-mortgage-application-settings-form p {
  color: rgb(193, 193, 193);
  font-size: 12px;
  font-weight: 800;
}

.fp-mortgage-application-settings-form label {
  color: #555555;
  /* margin-bottom: 11px; */
  margin-top: 5px;
  font-weight: 500;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: normal;
  font-size: 14px;
}

.fp-mortgage-application-settings-form input,
.fp-mortgage-application-settings-form select {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  height: 42px;
  line-height: 17px;
  padding: 0 16px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
}

.fp-mortgage-application-settings-form select {
  line-height: 17px;
  color: #969292;
  font-size: 14px;
}
.fp-mortgage-application-settings-form input.form-control::placeholder {
  color: #6c757d;
  opacity: 0.3;
  font-size: 14px;
}

.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fp-modal-body-wrapper.remove {
  background: #0073f0;
  color: #ffffff;
  padding: 100px auto;
}

.fp-modal-body-wrapper.remove h4 {
  color: #ffffff;
}

.fp-modal-body-wrapper.remove .fp-remove-account-button {
  color: #fd2f2f;
  left: 75px;
  position: relative;
}
