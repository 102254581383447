.fp-landing-faqs .fp-landing-faqs-contents-wrapper.privacy {
  padding: 30px 0;
}
.fp-landing-faqs-contents-wrapper.privacy h4 {
  font-weight: 800;
  font-size: 27px;
  line-height: 36px;
  color: #121212;
  margin: 20px auto;
  text-align: left;
}

.fp-landing-faqs-contents-wrapper.privacy p {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  text-align: left;
  margin: 25px 0;
}
