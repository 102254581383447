.landing-page-nav .nav-item .nav-link {
  color: #00b1ab !important;
  font-weight: 550;
}
.landing-page-nav .nav-item .nav-link:hover {
  color: #000000 !important;
  font-weight: 550;
}

.landing-page-nav a.btn-landing-login {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 20px !important;
  margin: 5px auto;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff !important;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 5px 16px 0px rgb(0, 177, 171, 0.45);
  text-rendering: geometricPrecision;
}

.landing-page-nav a.btn-landing-login:hover {
  background-color: #000000;
  border: 1px solid #000000;
  box-shadow: 0px 5px 16px 0px rgb(0, 0, 0, 0.45);
}

.fp-hero-banner-text-content {
  padding: 120px 0 30px;
}

.fp-hero-banner-text-content h2 {
  width: 500px;
  left: 0;
  margin-top: 40px;
  font-weight: 800;
  font-size: 60px;
  line-height: 65px;
  color: #121212;
}

.fp-hero-banner-text-content p {
  width: 500px;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  line-height: 1.7;
  margin: 30px 0 0;
}

#fp-hero-banner .fp-hero-banner-img {
  /* position: absolute; */
  width: 685px;
  height: 587px;
  left: 0;
  top: 6px;
  margin-top: 40px;
  background-image: url("../Resource/fp-landing-hero-banner.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  z-index: 1;
  overflow-x: hidden;
}

.fp-hero-banner-text-buttons {
  margin-bottom: 40px;
}

.fp-hero-banner-text-buttons .fp-hero-banner-text-button-theme {
  background: #00b1ab;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  border-radius: 5px;
  padding: 14px 30px;
  color: #ffffff;
  margin-right: 20px;
}

.fp-hero-banner-text-buttons .fp-hero-banner-text-button-theme:hover {
  background: #000000;
  box-shadow: 0px 5px 16px 0px rgb(0, 0, 0, 0.45);
}

.fp-hero-banner-text-buttons .fp-hero-banner-text-button-outline {
  background: #ffffff;
  border-radius: 5px;
  padding: 14px 30px;
  color: #00b1ab;
  border: 1px solid #00b1ab;
}

#fp-partners.fp-partners {
  background: #022f8f;
  /* padding: 20px 0px; */
  /* margin-top: 100px; */
}

.fp-hero-banner-pattern {
  position: absolute;
  background: url(../Resource//fp-hero-banner-pattern.png);
  background-repeat: no-repeat;
  background-size: contain;
  /* z-index: 200; */
  /* background-position: 20%; */
  top: 40%;
  height: 209px;
  width: 209px;
  left: 0px;
  opacity: 0.4;
}

.fp-partners .fp-partners-icon {
  opacity: 0.7;
  text-align: center;
}

.fp-partners .fp-partners-icon-img.first-trust {
  background: url(../Resource/first-trust-logo.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 130px;
  width: 100%;
  margin-top: 15px;
}

.fp-partners .fp-partners-icon-img.stanbic-ibtc {
  background: url(../Resource/stanbic-ibtc-logo.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 150px;
  width: 100%;
  /* opacity: 0.43; */
}

.fp-partners .fp-partners-icon-img.standard-chartered {
  background: url(../Resource/standard-chartered-logo.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 100px;
  width: 100%;
  /* opacity: 0.43; */
  margin-top: 20px;
}

.fp-partners .fp-partners-icon-img.nmrc {
  background: url(../Resource/nmrc-logo.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  /* height: 100px;
  width: 100%; */
  height: 80px;
  width: 250px;
  margin-top: 14px;
}

.fp-partners .fp-partners-icon-img.access {
  background: url(../Resource/access-bank-ft.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 80px;
  width: 250px;
  opacity: 0.43;
  margin-top: 30px;
}

.fp-partners .fp-partners-icon-img.brent {
  background: url(../Resource/brent.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 70px;
  width: 250px;
  margin-top: 34px;
  opacity: 0.43;
}

#fp-simple-steps.fp-simple-steps {
  padding: 80px 0;
  background: #013dbd;
  background-image: url("../Resource/fp-landing-simple-steps-particles.png");
  background-repeat: no-repeat;
  background-position: 10%;
  background-size: contain;
  position: relative;
}

.fp-simple-steps .fp-simple-steps-wrapper {
  padding: 20px 0;
  margin-bottom: 100px;
}

.fp-simple-steps-wrapper .fp-landing-simple-steps-circle-pattern {
  background-image: url("../Resource/fp-landing-simple-steps-circle-pattern.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  /* z-index: 200; */
  background-position: 100%;
  top: 13%;
  width: 133.72px;
  height: 133.72px;
  right: 0px;
  mix-blend-mode: normal;
}

.fp-simple-steps .fp-simple-steps-wrapper h2 {
  color: #ffffff;
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  margin-bottom: 30px;
}
.fp-simple-steps-wrapper .fp-simple-steps-contents {
  margin: 50px auto;
}

.fp-simple-steps-contents .fp-simple-steps-affordability-img {
  background-image: url("../Resource/fp-simple-steps-affordability.png");
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: contain;
  height: 90px;
  width: 90px;
  display: block;
  margin: 0px auto 20px;
}

.fp-simple-steps-contents .fp-simple-steps-eligibility-img {
  background-image: url("../Resource/fp-simple-steps-eligibility.png");
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: contain;
  height: 90px;
  width: 90px;
  display: block;
  margin: 0px auto 20px;
}

.fp-simple-steps-contents .fp-simple-steps-apply-now-img {
  background-image: url("../Resource/fp-simple-steps-application.png");
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: contain;
  height: 90px;
  width: 90px;
  display: block;
  margin: 0px auto 20px;
}

.fp-simple-steps-wrapper .fp-simple-steps-contents h4 {
  font-weight: 700;
  font-size: 27px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.fp-simple-steps-wrapper .fp-simple-steps-contents p {
  margin: 30px 0;
  font-weight: 300;
  font-size: 17px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  line-height: 1.7;
}

.fp-simple-steps-wrapper
  .fp-simple-steps-contents
  a.fp-simple-steps-get-started-btn {
  background: #00b1ab;
  box-shadow: 0px 10px 14px #03339a;
  border-radius: 5px;
  padding: 14px 50px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  /* margin: auto 80px; */
}

.fp-simple-steps-wrapper
  .fp-simple-steps-contents
  a.fp-simple-steps-get-started-btn:hover {
  background: #000000;
  box-shadow: 0px 5px 16px 0px rgb(0, 0, 0, 0.45);
}

#fp-landing-mortgage-nhf-rates.fp-landing-mortgage-nhf-rates {
  background: #ffffff;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-rates-line-pattern {
  background-image: url("../Resource/fp-landing-rates-line-pattern.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  /* transform: rotate(59.56deg); */
  z-index: 200;
  background-position: 20%;
  top: 258%;
  width: 353px;
  height: 353px;
  left: -20px;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-nhf-rates-wrapper {
  background: #ffffff;
  padding: 30px;
  border: 1px solid #0dfff7;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: -60px;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-nhf-rates-wrapper h2 {
  text-align: center;

  font-weight: 800;
  font-size: 35px;
  color: #121212;
  line-height: 50px;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-rates-content,
.fp-landing-mortgage-nhf-rates .fp-landing-nhf-rates-content {
  margin: 20px auto;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-rates-content::before {
  content: "";
  background-color: #ccc;
  position: absolute;
  width: 1px;
  height: 160px;
  top: 24.5%;
  left: 88%;
  margin-left: 30px;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-rates-content h4,
.fp-landing-mortgage-nhf-rates .fp-landing-nhf-rates-content h4 {
  font-weight: normal;
  font-size: 80px;
  line-height: 105px;
  color: #121212;
  text-align: center;
}

.fp-landing-mortgage-rates-content .rate,
.fp-landing-nhf-rates-content .rate {
  font-weight: normal;
  font-size: 50px;
  line-height: 105px;
  color: #121212;
  text-align: center;
}

.fp-landing-mortgage-nhf-rates .fp-landing-mortgage-rates-content p,
.fp-landing-mortgage-nhf-rates .fp-landing-nhf-rates-content p {
  font-weight: 300;
  font-size: 18px;
  color: #121212;
  text-align: center;
  margin-bottom: 3px;
}

.fp-landing-mortgage-nhf-rates
  .fp-landing-mortgage-rates-content
  span.ordinary {
  font-weight: 800;
  font-size: 17px;
  color: #00b1ab;
  margin: auto 70px;
}

.fp-landing-mortgage-nhf-rates .fp-landing-nhf-rates-content span.nhf {
  font-weight: 800;
  font-size: 17px;
  color: #00b1ab;
  margin: auto 100px;
}

#fp-landing-why-choose-us.fp-landing-why-choose-us {
  background: #ffffff;
}

.fp-landing-why-choose-us .fp-landing-why-choose-us-dotted-pattern {
  background-image: url("../Resource/fp-landing-rates-dotted-patterns.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 200;
  /* background-position: 50%; */
  top: 258%;
  width: 139px;
  height: 339px;
  right: 0;
}

.fp-landing-why-choose-us .fp-landing-why-choose-us-wrapper {
  padding: 100px 0;
  margin-top: 70px;
}

.fp-landing-why-choose-us-wrapper .fp-landing-why-choose-us-text {
  padding: 20px;
  margin-top: 30px;
}

.fp-landing-why-choose-us-wrapper .fp-landing-why-choose-us-text::before {
  content: "";
  background-color: #ccc;
  position: absolute;
  width: 1px;
  height: 160px;
  top: 24.5%;
  left: 82%;
  margin-left: 30px;
}

.fp-landing-why-choose-us-text .fp-landing-why-choose-use-finance-logo {
  background: url("../Resource/finance-plus-logo_theme.png");
  background-repeat: no-repeat;
  background-position: 40%;
  background-size: contain;
  background-blend-mode: color-dodge;
  height: 60px;
  width: 100%;
  /* float: left;
  margin-bottom: 5px; */
}

.fp-landing-why-choose-us-wrapper .fp-landing-why-choose-us-text h4 {
  font-weight: 800;
  font-size: 35px;
  color: #121212;
  width: 300px;
  text-align: right;
  margin: 10px 0;
}

.fp-landing-why-choose-us-text-features-wrapper
  .fp-landing-why-choose-us-text-features {
  display: flex;
  padding: 10px 30px;
  justify-content: flex-start;

  font-weight: normal;
  font-size: 20px;
  color: #121212;
}

.fp-landing-why-choose-us-text-features-wrapper h4 {
  font-weight: 800;
  font-size: 35px;
  color: #121212;
  text-align: center;
  margin-bottom: 20px;
}

#fp-landing-testimonials.fp-landing-testimonials {
  background: #013dbd;
  /* padding: 100px 50px; */
  padding: 70px 0;
}

/* .fp-landing-testimonials .fp-landing-testimonials-content-wrapper {
  padding: 20px 0;
} */

.fp-landing-testimonials-content-wrapper h2 {
  font-weight: 800;
  font-size: 35px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}

.fp-landing-testimonials-content-wrapper .fp-landing-testimonials-content {
  padding: 25px 30px 10px;
  background: #ffffff;
  box-shadow: 0px 14px 39px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}

.fp-landing-testimonials-content-wrapper .fp-landing-testimonials-content p {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #121212;
}

.fp-landing-testimonials-content-wrapper
  .fp-landing-testimonials-content
  .fp-landing-testimonial-source {
  border-top: 1px solid rgb(196, 196, 196, 0.5);
  margin-top: 20px;
}

.fp-landing-testimonials-content .fp-landing-testimonial-source h4 {
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  color: #121212;
  margin-top: 15px;
}

.fp-landing-testimonials-content .fp-landing-testimonial-source p {
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  color: #121212;
  margin-top: -5px;
}

#fp-landing-faqs.fp-landing-faqs {
  background: #f4f4f4;
  background-image: url("../Resource/fp-faqs-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.fp-landing-faqs .fp-landing-faqs-contents-wrapper {
  padding: 80px 0;
}

.fp-landing-faqs-contents-wrapper h2 {
  font-weight: 800;
  font-size: 35px;
  text-align: center;
  color: #121212;
}

.fp-landing-faqs-contents-wrapper .fp-landing-faqs-contents {
  padding: 30px 0;
}

.fp-landing-faqs-contents.fp-faqs-border-line {
  border-top: 1px dotted #c4c4c4;
}
.fp-landing-faqs-contents-wrapper .fp-landing-faqs-contents h4 {
  font-weight: 800;
  font-size: 27px;
  line-height: 36px;
  color: #121212;
  margin: 20px auto;
  text-align: left;
}

.fp-landing-faqs-contents-wrapper .fp-landing-faqs-contents p {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  text-align: left;
}

.fp-landing-faqs-contents-wrapper .fp-faqs-more {
  font-weight: 800;
  font-size: 22px;
  line-height: 36px;
  color: #00b1ab;
}

.fp-landing-faqs-contents-wrapper .fp-faqs-more:hover {
  color: #121212;
}

#fp-call-action.fp-call-action {
  background: #fafafa;
  padding: 20px;
}

.fp-call-action .fp-call-action-wrapper {
  padding: 30px 100px;
}

.fp-call-action-wrapper h2 {
  font-size: 35px;
  line-height: 80px;
  color: #121212;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 800;
}

.fp-call-action-wrapper .fp-call-action-buttons {
  padding: 20px 20px 30px;
  text-align: center;
}

.fp-call-action .fp-call-action-button-theme {
  background: #00b1ab;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  border-radius: 5px;
  padding: 14px 30px;
  color: #ffffff;
  margin-right: 20px;
  font-weight: 800;
}

.fp-call-action .fp-call-action-button-theme:hover {
  background: #000000;
  box-shadow: 0px 5px 16px 0px rgb(0, 0, 0, 0.45);
}

.fp-call-action .fp-call-action-button-outline {
  background: #ffffff;
  border-radius: 5px;
  padding: 14px 30px;
  color: #00b1ab;
  border: 1px solid #00b1ab;
  font-weight: 800;
}

/* Modals */
.fp-check-mortgage-application-status {
  background: #00b1ab;
  padding: 0px 20px;
}

.fp-check-mortgage-application-status .fp-login-page-logo {
  background: url("../Resource/finance-plus-logo-light-bottom.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  margin: 15px auto 0;
  background-position: -7%;
}

.fp-check-mortgage-application-status .fp-login-page-logo.result {
  background-position: -4%;
  /* max-width: 100px; */
  /* margin: 0 !important; */
}

.fp-check-mortgage-application-status h2 {
  color: #ffffff;
  font-size: 15px;
}

.fp-check-mortgage-application-status p {
  color: #ffffff;
}

.fp-check-mortgage-application-status-body-wrapper {
  padding: 20px;
}

.fp-check-mortgage-application-status-body-wrapper
  .fp-check-mortgage-application-status-body-wrapper-bg {
  background-image: url("../Resource/fp-landing-footer-pattern.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  mix-blend-mode: inherit;
  width: 100%;
  height: 520px;
  top: 0%;
}

.fp-check-mortgage-application-status-body-wrapper p {
  font-size: 14px;
  text-align: center;
}

.fp-check-mortgage-application-status-body-wrapper
  .fp-login-form-wrapper
  label {
  font-size: 15px;
}

.fp-modal-result-button {
  background-color: #00b1ab;
  border: 1px solid #00b1ab;
  padding: 10px 80px !important;
  line-height: 1.5;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none !important;
  box-shadow: 0px 10px 14px rgba(0, 177, 171, 0.28);
  text-transform: uppercase;
  width: 100%;
  font-size: 13px;
}

.fp-check-mortgage-application-status-body-wrapper
  .fp-mortgage-loan-amount-repayment-interest-rate
  .fp-mortgage-loan-amount-wrap
  h2::before {
  content: "₦";
  margin-right: 0px;
}
.fp-mortgage-application-loan-info-display.result {
  background: none;
}

.fp-mortgage-application-loan-info-display.result
  .fp-mortgage-application-reference-no-loan-status
  .fp-mortgage-application-date {
  margin: 0;
}
