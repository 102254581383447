.toaster-wrapper {
  position: fixed;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin: 0 auto;
  width: 100%;
}

.toaster-wrapper .toaster-body {
  background: palegoldenrod;
  border-radius: 5px;
  padding: 10px;
  width: 80%;
  max-width: 500px;
  color: black;
  text-align: center;
}